import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as cryptoJs from 'crypto-js';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// COMMAND TO GET JSON FROM S3 FOR DEVELOPMENT
// aws s3 cp s3://www.osp-devt-r2.com/assets/data src/assets/data --recursive

/* 
    {
        key: string,
        name: string,
        fileName: string,
        group: string,
        isDone: boolean,
        progress: observable
    }
*/
let JSON_LIST: any[] = [
    { key: "BMM011", name: "params" },
    { key: "BMM012", name: "refCds" },
    { key: "BMM016", name: "gender" },
    { key: "BMM017", name: "agent" },
    { key: "BMM018", name: "maritalStat" },
    { key: "BMM019", name: "postalCds" },
    { key: "BMM020", name: "category" },
    { key: "BMM021", name: "riskProfCd" },
    { key: "BMM023", name: "peril" },
    { key: "BMM025", name: "carCompanies" },
    { key: "BMM026", name: "makes" },
    { key: "BMM027", name: "engineSeries" },
    { key: "BMM028O", name: "veDetailsOSP" },
    { key: "BMM029", name: "lines" },
    { key: "BMM030", name: "sublines" },
    { key: "BMM031", name: "tax" },
    { key: "BMM035", name: "carType" },
    { key: "BMM036", name: "sublinePurposes" },
    { key: "BMM037", name: "perilAllowedTsi" },
    { key: "BMM038", name: "plans" },
    { key: "BMM039", name: "planPerils" },
    { key: "BMM042", name: "quickLinks" },
    { key: "BMM044", name: "users" },
    { key: "BMM045", name: "banks" },
    { key: "BMM046", name: "country" },
    { key: "BMM047", name: "province" },
    { key: "BMM048", name: "nationality" },
    { key: "BMM049", name: "occupation" },
    { key: "BMM050", name: "idList" },
    { key: "BMM051", name: "contracts" },
    { key: "BMM053", name: "introDetail" },
    { key: "BMM054", name: "veUsages" },
    { key: "BMM055", name: "src" },
    { key: "BMM056", name: "srcExt" },
    { key: "BMM057", name: "bma" },
    { key: "BMM058", name: "referror" },
    { key: "BMM059", name: "project" },
    { key: "BMM061", name: "client" },
    { key: "BMM064", name: "ccInstallments" },
    { key: "BMM065", name: "policyEmail" },
    { key: "BMM070", name: "BMM070" },
    { key: "BMM071", name: "BMM071" },
    { key: "BMM072", name: "agentGrps" },
    { key: "BMM073", name: "BMM073" },
    { key: "BMM074", name: "faq" },
    { key: "BMM075", name: "faqProduct" },
    { key: "BMM086", name: "paymentOptions" },
    { key: "BMM087", name: "cancelReason" },
    { key: "BMM090", name: "mvType" },
    { key: "BMM091", name: "mvPremType" },
    { key: "BMM092", name: "city" },
    { key: "BMM093", name: "userType" },
    { key: "BMM094", name: "siLimit" },
    { key: "BMM095", name: "BMM095" },
    { key: "BMM096", name: "BMM096" },
    { key: "BMM097", name: "BMM097" },
    { key: "BMM098", name: "BMM098" },
    { key: "BMM099", name: "BMM099" },
    { key: "BMM100", name: "BMM100" },
    { key: "BMM103", name: "paytOptPerLine" },
    { key: "BMM107", name: "fireItemCd" },
    { key: "BMM108", name: "firePlanItemCd" },
    { key: "BMM112", name: "relationship" },
    { key: "BMM113", name: "BMM113" },
    { key: "BMM114", name: "planBenefitsAndCoverages" },
    { key: "BMM115", name: "viewPlanPerUser" },
    { key: "BMM116", name: "currency" },
    { key: "BMM117", name: "currencyRate" },
    { key: "BMM118", name: "BMM118" },
    { key: "BMM119", name: "BMM119" },
    { key: "BMM122", name: "mvTypePerPlan" },
    { key: "BMM123", name: "mvPremTypePerPlan" },
    { key: "BMM124", name: "filterPerPlan" },
    { key: "BMM132", name: "userTypeClientGrp" },
    { key: "BMM134", name: "BMM134" },
    { key: "BMM135", name: "BMM135" },
    { key: "BMM136", name: "paValidations" },
    { key: "BMM120", name: "sourceFund" },
    { key: "BMM150", name: "BMM150" },
    { key: "BMM154", name: "perilAllowedTsiperCarComp" },
    { key: "BMM159", name: "popup" },
    { key: "BMM160", name: "propertyDetails" },
    { key: "BMM162", name: "BMM162" },
    { key: "BMM163", name: "BMM163" },
    { key: "BMM164", name: "propAssessmentWordings" },
    { key: "BMM165", name: "preApprovedSubdivision" },
    { key: "BMM166", name: "hcaPlan" },
    { key: "BMM167", name: "zone" },
    { key: "BMM168", name: "BMM168" },
    { key: "BMM169", name: "BMM169" },
    { key: "BMM170", name: "BMM170" },
    { key: "BMM171", name: "BMM171" },
    { key: "BMM172", name: "BMM172" },
    { key: "BMM173", name: "BMM173" },
    { key: "BMM174", name: "BMM174" },
    { key: "BMM175", name: "BMM175" },
    { key: "BMM176", name: "BMM176" },
    { key: "BMM177", name: "BMM177" },
    { key: "BMM180", name: "fireRiskSiLimit" },
    { key: "BMM181", name: "minPremLimit" },
    { key: "BMM182", name: "effDatePerUser" },
    { key: "BMM183", name: "fireConstYr" },
    { key: "BMM185", name: "srcCdPerAgent" },
    { key: "BMM186", name: "srcExtCdPerAgent" },
    { key: "HELP",   name: "help" },
    { key: "nonRequiredPlateNo", name: "nonRequiredPlateNo" }
];

let preRequisites:any = {
    quotation:{
        'vehicle-details' :{
            list: ['referror','veUsages', 'mvType', 'siLimit', 'banks', 'carCompanies', 'makes', 'engineSeries', 'mvPremType', 'veDetailsOSP']
        },
        'choose-plan':{
            list: ['referror','sublines', 'planPerils', 'peril', 'plans', 'params']
        },
        'property-assessment' :{
            list: ['preApprovedSubdivision', 'province', 'city']
        },
        'property-details' :{
            list: ['referror','country', 'province', 'city', 'postalCds', 'banks', 'propertyDetails', 'fireRiskSiLimit', 'fireConstYr']
        },
        'coverage-perils':{
            list: ['agent', 'bma', 'client', 'peril', 'planPerils', 'sublines', 'perilAllowedTsi', 'siLimit', 'veDetailsOSP', 'userType', 'makes', 'refCds', 'params', 'mvPremType', 'engineSeries', 'carCompanies', 'srcExt', 'referror', 'BMM070', 'BMM071', 'BMM073', "BMM113", 'BMM118', 'BMM119', 'userTypeClientGrp', 'BMM134', 'BMM135', 'BMM150','effDatePerUser','perilAllowedTsiperCarComp', 'BMM095', 'BMM096', 'BMM097', 'BMM098', 'BMM099', 'BMM100']
        },
        'review-premium-charges':{
            list: ['cancelReason', 'peril', 'tax', 'help']
        },
        'email-print':{
            list:['policyEmail']
        }
    },
    policy:{
        'personal-details':{
            list: ['referror']
        },
        'vehicle-details' :{
            list: ['veUsages', 'mvType', 'siLimit', 'banks', 'carCompanies', 'makes', 'engineSeries', 'mvPremType', 'veDetailsOSP']
        },
        'property-details' :{
            list: ['country', 'province', 'city', 'postalCds', 'banks', 'propertyDetails', 'fireRiskSiLimit', 'fireConstYr']
        },
        'customer-information':{
            list: ['referror','gender', 'maritalStat', 'province', 'city', 'postalCds', 'country', 'refCds', 'occupation', 'nationality', 'category', 'userType', 'riskProfCd', 'idList']
        },
        'acceptance':{
            list: ['plans', 'sublines', 'params', 'userType', 'help', 'peril', 'tax', 'agent', 'src', 'srcExt', 'bma', 'referror', 'project', 'client', 'mvPremType','effDatePerUser']
        },
        'email-print':{
            list:['referror','policyEmail']
        },
        'summary':{
            list: ['referror', 'peril', 'help', 'quickLinks', 'tax', 'city', 'postalCds', "paytOptPerLine", 'cancelReason', 'maritalStat', 'province', 'city']
        },
        'payment' :{
            list: ['referror', 'paytOptPerLine']
        }
    },
    profile: {
        list: [ "country", "province", "city", "postalCds", "gender", "maritalStat", "nationality" ]
    },
    renewal: {
        list: [ "province", "city", "postalCds", "maritalStat", "paytOptPerLine" ]
    },
    payment: {
        list: ['referror', "province", "city", "postalCds", "maritalStat", "paytOptPerLine" ]
    },
    'request-quotation': {
        list: [ "province", "city", "postalCds", "maritalStat", "paytOptPerLine" ]
    },
    dashboard: {
        list: ["referror" ]
    }
};

const addtlDataKeys:any[] = ['referror','agent', 'users', 'bma', 'srcCdPerAgent','srcExtCdPerAgent'];

@Injectable({
    providedIn: 'root'
})
export class JsonDataService {

    private encryptKey: string = "";
    gtagEncryptKey: string = "";

    private fileDataResult:any;
    data: any = {};

    apiUrl: string = "";

    loadingSource = new Subject<Boolean>();
    loading$ = this.loadingSource.asObservable();

    msgBoxSource = new Subject<any>();
    msgBox$ = this.msgBoxSource.asObservable();

    doneAuthorizing: boolean = false;

    public onMaintenance: boolean = false;

    constructor (
        private httpClient: HttpClient
    ) { }

    init(): Promise<any> {
        this.setApiUrlForLocalhostTesting();
        this.getEncryptionKey();
        this.getGtagKey();
        return this.extractData();
    }

    async setApiUrlForLocalhostTesting(): Promise<void> {
        let apiUrl = "";

        try {
            const response = await this.httpClient.get<any>("assets/data/config.json").toPromise();
            apiUrl = response.apiUrl;
        } catch (e) {
            // do action here
        }

        this.apiUrl = apiUrl;
    }

    async getEncryptionKey(): Promise<void> {
        try {
            const key = await this.httpClient.get<any>("assets/data/O4NTDGHewlaeaBW5NzqjNGLb.json").toPromise();
            for (let a = 1; a <= (key.length / 5); a++) {
                this.encryptKey += key.substr((5 * a) - 1, 1);
            }
        } catch (e) {
            this.onMaintenance = true;
        }
    }

    async getGtagKey(): Promise<void> {
        try {
            const key = await this.httpClient.get<any>("assets/data/Ma145AJSCI12349AjnYags12.json").toPromise();
            for (let a = 1; a <= (key.length / 5); a++) {
                this.gtagEncryptKey += key.substr((5 * a) - 1, 1);
            }
        } catch (e) {
            // do action here
        }
    }

    async extractData():Promise<any> {
        try {
            await this.setFileDataResult();

            JSON_LIST.forEach((file) => {
                let data = this.fileDataResult[file.key];
                if (typeof data === "string") {
                    file.isDone = false;
                    file.observable = this.httpClient.get<any>("assets/data/" + data + ".json").pipe(
                        tap((r:any) => {
                            this.data[file.name] = JSON.parse(this.decrypt(r));
                            file.isDone = true;
                        })
                    );
                    delete this.fileDataResult[file.key];
                } else {
                    file.isDone = true;
                    this.data[file.name] = this.fileDataResult[file.key];
                    delete this.fileDataResult[file.key];
                }
            });

            let url = window.location.pathname;
            this.loadChildParams(url.substring(1) || "");
            let waiting = this.checkLoadingParams(url);
            if (typeof waiting !== "boolean") {
                await waiting.toPromise();
            }
            this.fileDataResult = {};
        } catch (e) {
            // do action here
        }
    }

    async setFileDataResult():Promise<void> {
        try {
            const fileName = "MUNmMsb3GuhcuByoA84c5X01";
            const fileData = await this.httpClient.get<any>("assets/data/" + fileName + ".json").toPromise();
            this.fileDataResult = JSON.parse(this.decrypt(fileData));
        } catch (e) {
            if (!this.onMaintenance) {
                await this.setFileDataResult();
            }
        }
    }

    decrypt(data: string): string {
        return cryptoJs.AES.decrypt(data, this.encryptKey).toString(cryptoJs.enc.Utf8);
    }

    encrypt(data: any): string {
        if (data == undefined) {
            data = null;
        }
        return cryptoJs.AES.encrypt(JSON.stringify(data), this.encryptKey).toString();
    }

    contorlLoading(action: boolean): void {
        this.loadingSource.next(action);
    }

    contorlMsgBox(type: string, msg?: any, title?: any): void {
        this.msgBoxSource.next({
            msg: msg,
            title: title,
            type: type
        });
    }

    saveToStorage(key: string, obj: any): void {
        // sessionStorage.setItem(key, this.encrypt(obj));
        sessionStorage.setItem(key, this.encrypt(obj));
    }

    retrieveFromStorage(key: string): any {
        let obj = null;
        // let objStr = sessionStorage.getItem(key);
        let objStr = sessionStorage.getItem(key);
        if (objStr) obj = this.decrypt(objStr);
        return obj;
    }

    //REMOVE IF UNNECESSARY
    saveTosessionStorage(key: string, obj: any): void {
        sessionStorage.setItem(key, this.encrypt(obj));
    }

    retrieveFromsessionStorage(key: string): any {
        let obj = null;
        let objStr = sessionStorage.getItem(key);
        if (objStr) obj = this.decrypt(objStr);
        return obj;
    }

    getHelp(tableName: string, columnName: string): string {
        return this.data.help?.filter((a: any) =>
            a.tableName === tableName && a.columnName === columnName
        )[0]?.comment || "";
    }

    parseBase64(str: string): String {
        let words = cryptoJs.enc.Base64.parse(str);
        return cryptoJs.enc.Utf8.stringify(words);
    }

    checkLoadingParams(url?:string): Observable<boolean> | boolean{
        let paramList:any[] = [];
        
        this.contorlLoading(true);

        let reqObj = preRequisites;

        if(url){
            for(let path of url.substring(1).split('/')){
                if(!reqObj){
                    break;
                }
                reqObj = reqObj[path];
            }
            paramList = reqObj?.list || [];
        }
        let pending: Promise<boolean>[] = [];
    
        JSON_LIST.filter(a => paramList.indexOf(a.name) != -1)
            .forEach(e => {
                !e.isDone && e.subject && pending.push(e.subject)
            });
        
        if(pending.length > 0){
            return  forkJoin(pending)
            .pipe(
                map((d:any)=>{
                    this.contorlLoading(false);
                    return d =true;
                })
            );
        }else{
            this.contorlLoading(false);
            return true;
        }


    }

    loadChildParams(url:string) {
        let paramList:any[] = this.getSubLists(url,preRequisites);
        JSON_LIST.filter(a => paramList.indexOf(a.name) != -1)
            .forEach(e => {
                if(!e.isDone && e.observable && !e.subject && (!addtlDataKeys.includes(e.name) || addtlDataKeys.indexOf(e.name)== 0)){
                    // e.source = new Subject<boolean>();
                    // e.subject = e.source.asObservable();
                    // e.observable.subscribe(()=>{
                    //     e.source.next(true);
                    // })
                    e.subject = new Promise((resolve,reject)=>{
                        e.observable.subscribe(()=>{
                            resolve(true);
                        })
                    })
                }
            });
        
    }

    getSubLists(url:string, obj:any):string[]{
        url = url.split('/')[0];
        let list:string[] = [];
        if(obj[url]){
            list = list.concat(obj[url].list || []);
            for(let key of Object.keys(obj[url])){
                if(key != 'list'){
                    list = list.concat(this.getSubLists(key,obj[url]));
                }
            }
        }
        return list;
    }

    setAddtlObservables(observable:Observable<any>){
        for(let d of JSON_LIST){
            if(addtlDataKeys.includes(d.name)){
                d.observable = observable;
                d.isDone = false;
                d.subject = undefined;
            }
        }
    }
}