import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { PopupService } from 'src/app/services/popup.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ExternalService } from 'src/app/services/external.service';

@Component({
    templateUrl: './introduction.html'
})
export class IntroductionComponent {

    form: FormGroup = this.formBuilder.group({
        language: [this.productService.productInfo.language ?? 'E'],
    });

    defaultLangSelections: any[] = [
        { value: 'E', text: 'English' },
        // { value: 'F', text: 'Filipino' },
        { value: 'T', text: 'Taglish' },
    ];
    langSelections: any[] = [];

    productInfo: any = {};
    introTitle: string = "";
    introText: string = "";
    imagePath: string = "";
    introBigTitle: string = "";
    doNotShowAgain: boolean = false;
    prefLang: any[] = [];
    showLangSelection: string = "";

    constructor(
        // PUBLIC
        public userDetailsService: UserDetailsService,
        public parameterService: ParameterService,

        // PRIVATE
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router,
        private pageValidationService: PageValidationService,
        private stepperDtlService: StepperDtlService,
        private productService: ProductService,
        private jsonDataService: JsonDataService,
        private formBuilder: FormBuilder,
        private popupService: PopupService,
        private externalService: ExternalService
    ) {
        this.productInfo = this.productService.productInfo;

        if(this.productService.productInfo.lineCd === 'paytRenewal') {
            // this.pageValidationService.validatePageAccessibility();

            var imagePath = this.jsonDataService.data.params.filter((a: any) => a.paramName == 'REN_PREMPAYT_INTRO_IMAGE')[0]['paramValueV'];
            var introTitle = this.jsonDataService.data.params.filter((a: any) => a.paramName == 'REN_PREMPAYT_INTRO_TITLE')[0]['paramValueV'];
            var introText = this.jsonDataService.data.params.filter((a: any) => a.paramName == 'REN_PREMPAYT_INTRO_DESC')[0]['paramValueV'];

            this.introBigTitle = 'KEEP YOUR COVER ON';

            this.introTitle = introTitle;
            this.introText = introText;
            this.imagePath = imagePath;
        } else {
            this.setLineSubline();
            this.pageValidationService.validatePageAccessibility();
            this.initializeStepperDetail();

            for (const intro of this.jsonDataService.data.introDetail) {
                if (intro.subLineCd === this.productService.productInfo.sublineCd && intro.lineCd === this.productService.productInfo.lineCd) {
                    this.introTitle = intro.introTitle;
                    this.introText = intro.introDesc;
                    this.imagePath = intro.imagePath;
                }
            }

            if (this.productService.productInfo.lineCd === 'HA') {
                this.introBigTitle = 'INTRODUCTION';
                this.setLanguage();
            }
            else {
                this.introBigTitle = 'GET THE RIGHT COVER';
            }
        }

        setTimeout((e: any) =>
            popupService.popup.open('IN'), 0);
    }

    ngOnInit(): void {
        try {
            this.externalService.destroyQuotationData('used')
            const firePrefill = this.externalService.getQuotationData('fire') ?? {};
            const motorPrefill = this.externalService.getQuotationData('motor') ?? {};
            if (!!Object.keys(firePrefill).length || !!Object.keys(motorPrefill).length) {
                this.move()
            }
        } catch (error) {
            console.error('Error on fire/motor prefilling', error)
        }
    }

    private setLineSubline(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            if (p.params?.p) {
                try {
                    const quoteDetails = JSON.parse(this.jsonDataService.decrypt(p.params.p));

                    this.productService.setProductInfo2({
                        creationProcess: "quotation",
                        lineCd: quoteDetails.lineCd,
                        sublineCd: quoteDetails.sublineCd
                    });

                    this.productInfo.creationProcess = "quotation";
                    this.productInfo.lineCd = quoteDetails.lineCd;
                    this.productInfo.sublineCd = quoteDetails.sublineCd;

                    this.location.replaceState("/quotation");
                } catch (e) {
                    this.router.navigateByUrl("/");
                }
            }

            // CI HCP  - U2FsdGVkX18MWR7GIgZhlftNDJRJ246I27QUpObCyFOgH6zfpJ6e13lhq0c4D%2B8p2zvE8NyhHTEnJjfFfAEjQA%3D%3D
            // FI FIR  - U2FsdGVkX19Kxfk3hr1KmHyOx3VhENyTJTrGzra5UF%2BVRnoUzOYfWc7Ywl6dfskEkOZvsiS1AyO7u5Vk9K8Rdg%3D%3D
            // HA HCA  - U2FsdGVkX188WnctMZO3nCn5nO%2BfhyXHyuy1NeUlP6%2FAuWakBj3L0GX05oE83EwxDiSzCAeoJXZYQZlPzbm3Eg%3D%3D
            // MC COM  - U2FsdGVkX1%2BIPvIJ82q2VXPQM1Cyl1or5jmqlMBH51YPJWkJAeu89gAR6SCEprKQe7d2cSDCiQEY8mwI%2BfObtQ%3D%3D
            // MC CTPL - U2FsdGVkX195lMsoHpGsawZ8qRuhxwbxhPhIWp9oQYgfXAmvPe%2BQqcJo8qWGHQrRaIdouYnCNkGURZtnwnnxsw%3D%3D
            // MC PCP  - U2FsdGVkX19o7HqeEWHBJk16y%2BhHYO9cNfsp7OR9vvzXfV9zKK8RUg6YyMFCI6qAo2eYXctN7n6z6znpGje1%2FQ%3D%3D
            // PA FPA  - U2FsdGVkX1%2Fs8%2BsF8OwbiFjmEx5jZ8u1IZ0ASYLPsIwSTT8nRiYVesLLjik%2FKSjdYGltzKnEbuDELsknzofung%3D%3D
            // PA IND  - U2FsdGVkX19kdXmWEwW%2FkSCgGDXpggor0oK0ei8zKSJEfUz2NxOPTOcc6bNuZCxeqD%2B9XUmKhTepH7cA8hpYXw%3D%3D
            // PA PAB  - U2FsdGVkX197iLnBk%2FV4hxFnkJ0rQ%2Fkw%2FBC7q6sinCn62wtGAMPSCytfpHVPICr9JQbdbvm2GWw%2FE%2FQf%2F4zLwg%3D%3D
        });
    }

    initializeStepperDetail(): void {
        this.stepperDtlService.showStepperDtl = false;
    }

    // private setLanguage(): void {
    //     let languageVals = this.defaultLangSelections.map((l: any) => l.value);
    //     this.prefLang = this.parameterService.paramV('PREF_LANGUAGE')?.split(',').filter((i: any) => languageVals.includes(i)) ?? ['E'];
    //     let filteredLang = this.defaultLangSelections.filter((l: any) => this.prefLang.includes(l.value));
    //     this.langSelections = filteredLang;
    //     this.form.patchValue({ language: languageVals.includes(this.langSelections[0]?.value) ? this.langSelections[0]?.value : 'E' });
    // }

    private setLanguage(): void {
        this.showLangSelection = this.parameterService.paramV('PREF_LANGUAGE') ?? 'N';
        this.langSelections = this.defaultLangSelections;
        if (this.showLangSelection === 'N') {
            this.form.patchValue({ language: 'E' });
        }
    }

    move(): void {
        if(this.productService.productInfo.lineCd === 'paytRenewal') {
            this.router.navigate(["/renewal-payment/policy-details"]);
        } else {
            const accountType = this.userDetailsService.accountType;
            if (accountType && accountType !== "P" && this.doNotShowAgain) {
                this.jsonDataService.saveToStorage("ndX93#SI$%mdpJ98D90d8c4m@f=", this.userDetailsService.name);
            }
            if (this.productService.productInfo.lineCd === 'HA') {
                this.productInfo.language = this.form.get("language")?.value;
                this.productInfo.planEvaluated = "N";
                this.productService.setProductInfo2(this.productInfo);
            }

            if (this.productService.productInfo.lineCd === "HA") {
                this.router.navigate(["/quotation/property-assessment"]);
            } else {
                this.router.navigate(["/quotation/choose-plan"]);
            }
        }        
    }
}