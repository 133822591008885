import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { Router } from '@angular/router';
import { ParameterService } from 'src/app/services/parameter.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { SecurityService } from 'src/app/services/security.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/services/popup.service';
import { filter } from 'rxjs/operators';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

@Component({
    templateUrl: './coverage-perils.html'
})
export class CoveragePerilsComponent implements OnInit {

    minEffDate: Date;
    maxEffDate: Date;
    sameDayTag: Boolean;

    perilsData: any = [];
    hasAccount: boolean = false;
    haveData: boolean = false;
    isSelected: boolean = false;
    minPremTag:boolean = false;
    minPremMsg:string = this.jsonDataService.data.minPremLimit.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.minPremText;

    prodVouchCdExist = !!this.productService.productInfo.prodVouchInfo?.prodVouchCd;
    quoteId: number = this.productService.productInfo.quoteId;
    lineCd: string = this.productService.productInfo.lineCd;
    editFlag: boolean = false;
    promoError: any = '';

    contractType: any[] = [];
    noOfYears: any[] = [];

    dateNow: any = new Date();

    selectedPeril: any = {
        "perilCd": 0,
        "perilName": "",
        "premRt": 0,
        "premAmt": 0,
        "tsiAmt": 0
    };

    perilInfo: any = {
        "totSumInsured": 0,
        "premRate": 0,
        "premAmt": 0,
        "serviceFeeAmt": 0,
        "servicefeeRate": 0,
        "ncd": 0,
        "amount": 0,
        "totPremAmt": 0,
        "commision": 0,
        "comAmount": 0
    };

    sublineTime: any = '';
    // ? default agent cd
    bpiAgentCd: string = "";
    editableNoOfDaysParam: string = "";
    // ? OSP: Mandatory Fields for Policies under 200104
    agentSelected: string = "";
    // ? error display if clientType entered is not valid
    clientTypeError: any = '';
    // ? clientTypeBm
    clientTypeBmCode: any = '';
    // ? summarized premium tag
    summarizedPremTag = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.summarizedPremTag;
    // ? Eff Date Range (in days)(if null, default to 30)
    effDateRangeDays = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.effDateRange ?? 30;
    premiumCharges: any = {};
    // ? default clientType of user
    defaultClientTypeGrp: any = null;
    // ? editable client type
    editableClientType: any = '';
    // ? error display if no of dependents entered is not valid
    depWithPremError: string = '';
    // ? max number of dependents
    maxDepWithPrem: number = this.jsonDataService.data.paValidations.filter((a: any) => a.LINE_CD == this.productService.productInfo.lineCd && a.SUBLINE_CD == this.productService.productInfo.sublineCd && a.PLAN_CD == this.productService.productInfo.planInfo.planCd)[0]?.MAX_DEP_WITH_PREM ?? 0;
    private showComputationIsClicked: boolean = false;
    editablePD: boolean = false;

    coverageHeaderngClass = {
        'col-case': true,
        'col-5': this.maxDepWithPrem > 0 && ['PA', 'CI'].includes(this.lineCd) && (!this.userDetails.accountType || ['B', 'P'].includes(this.userDetails.accountType)),
        'col-4': !this.maxDepWithPrem && !this.userDetails.accountType || (['A', 'E', 'B', 'P'].includes(this.userDetails.accountType)),
        'col-3': this.userDetails.accountType && this.userDetails.accountType != 'P' && this.lineCd != 'CI'
    }
    // AUTO-COMPLETE OPTIONS
    filteredAgentDetailOptions!: Observable<any>;
    filteredSourceCodeDetailOptions!: Observable<any>;
    filteredSourceExtensionDetailOptions!: Observable<any>;
    filteredBmaDetailOptions!: Observable<any>;
    filteredReferrorCodeDetailOptions!: Observable<any>;
    filteredProjectCodeDetailOptions!: Observable<any>;
    //filteredClientTypeGroupDetailOptions: Observable<any>;
    disableShowCompBtn: boolean = false;

    coverageInfo = this.formBuilder.group({
        conType: [this.productService.productInfo.coverageInfo?.conType, [Validators.required]],
        noOfYears: [this.productService.productInfo.coverageInfo?.noOfYears, [Validators.required]],
        effDate: ['', [Validators.required]],
        expDate: [{ value: '', disabled: true }, [Validators.required]],
        tempId: [this.productService.productInfo.perilInfo?.tempId, []],
        quoteId: this.quoteId,
        btnHolder: ['', []],
        promoCode: [''],
        promoUserType: [''],
        promoAgentGrp: [''],
        promoIssCd: [''],
        covCompTag: [''],
        grpTag: [''],
        noOfDays: [this.productService.productInfo.coverageInfo?.noOfDays],
        depWithPrem: [this.productService.productInfo.coverageInfo?.depWithPrem ?? 0],
        agentDetail: ['', [this.formService.autocomplete()]],
        bmaDetail: ['', [this.formService.autocomplete()]],
        clientTypeGroupDetail: [''] //clientTypeGroupDetail: ['', [this.formService.autocomplete()]]
    });

    policyHeaderDetailsForm: FormGroup = this.formBuilder.group({
        sourceCodeDetail: ["", [this.formService.autocomplete()]],
        sourceExtensionDetail: ["", [this.formService.autocomplete()]],
        referrorCodeDetail: ["", [this.formService.autocomplete()]],
        projectCodeDetail: ["", [this.formService.autocomplete()]],
    });

    coverages: any;
    ara: any;
    peril: any;
    taxes: any;
    policyHeader: any;
    benefitsCoverages: any;

    rows: FormArray = this.formBuilder.array([]);
    tableForm = this.formBuilder.group({ 'perils': this.rows });

    dataSource!: MatTableDataSource<any>;
    displayedColumns: string[] = ["perilName", "amountCovered", "premAmount"];

    m070: any = [];
    m071: any = [];
    m073: any = [];
    m037: any = [];
    m093: any = [];
    m094: any = [];
    m095: any = [];
    m096: any = [];
    m097: any = [];
    m098: any = [];
    m099: any = [];
    m100: any = [];
    m113: any = [];
    m150: any = [];
    m118: any = [];
    m119: any = [];

    fmv: any = {};


    perilMtn = this.jsonDataService.data.peril.filter((a: any) => a.lineCd == this.lineCd);
    planPerilMtn = this.jsonDataService.data.planPerils.filter((a: any) => a.lineCd == this.lineCd && a.planCd == this.productService.productInfo.planInfo.planCd && a.sublineCd == this.productService.productInfo.sublineCd)

    compareFn: ((f1: any, f2: any) => boolean) = this.compareByValue;

    agentType: String = '';

    eqZoneCd: String = '';
    tyZoneCd: String = '';
    flZoneCd: String = '';
    zoneCd: String = '';

    deductibles:any[] = [];

    @ViewChild(MatSort)
    sort!: MatSort;

    computeDependencies:any[] = ['effDate','clientTypeGroupDetail','agentDetail','noOfDays','depWithPrem'];

    constructor(
        // PUBLIC
        public formService: FormService,
        public userDetails: UserDetailsService,
        public stepperDtlService: StepperDtlService,
        public autocompleteService: AutocompleteService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private dialogBoxService: DialogBoxService,
        private securityService: SecurityService,
        private parameterService: ParameterService,
        private popupService: PopupService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.hasAccount = !!this.userDetails.name;
        this.agentType = this.jsonDataService.data.agent.find((a: any) => a.agentCd === this.userDetails.userId)?.agentType;
        // STEPPER DETAIL
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.stepperDtlStepNo = this.stepperCheck().number;
        this.stepperDtlService.titleStepperDtl = this.stepperCheck().title;
        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: 'move', actionParam: 'back' },
            { class: "btn5", label: "Save", action: 'saveCoverageInfo', isShow: String(this.hasAccount && this.haveData) },
            { class: "btn2", label: this.stepperCheck().label, action: 'move', actionParam: 'next', isShow: String(this.haveData) }
        ];

        //this.utilService.setFormGroup(this.tableForm);
        this.bpiAgentCd = this.parameterService.paramV("BPI_AGENT_CD");
        this.editableNoOfDaysParam = this.parameterService.paramV("EDIT_MAX_NO_OF_DAYS");

        // SET AUTO-COMPLETE INITIAL VALUES
        if(this.userDetails.accountType && this.userDetails.accountType != 'P'){
            this.filteredAgentDetailOptions = this.autocompleteService.set(this.coverageInfo, "agentDetail", this.getAgents());
            this.filteredSourceCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "sourceCodeDetail", this.getSourceCodes());
            this.filteredSourceExtensionDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "sourceExtensionDetail", this.getSourceExtensions());
            this.filteredBmaDetailOptions = this.autocompleteService.set(this.coverageInfo, "bmaDetail", this.getBMAs());
            this.filteredReferrorCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "referrorCodeDetail", this.getReferrorCodes());
            this.filteredProjectCodeDetailOptions = this.autocompleteService.set(this.policyHeaderDetailsForm, "projectCodeDetail", this.getProjectCodes());
        }
        //this.filteredClientTypeGroupDetailOptions = this.autocompleteService.set(this.coverageInfo, "clientTypeGroupDetail", this.getClientTypeGroups());

        // ? initialize editable client type from logged in user
        this.editableClientType = this.jsonDataService.data.userType.find((a: any) => a.userType === (this.userDetails.accountType || 'P'))?.enableClientCd;

        let {accountType, userLevel} = this.userDetails;
        let {sublineCd, planInfo:{planCd}, lineCd} = this.productService.productInfo;
        let { allowDateRange, sublineTimeTag} = this.jsonDataService.data.effDatePerUser.filter(
            (a:any)=> 
                   a.userType == (accountType || 'P') 
                && a.userLevel == (userLevel || 0)
                && a.lineCd == lineCd
                && a.sublineCd == sublineCd
                && a.planCd == planCd)[0] || {};

        this.sublineTime = this.jsonDataService.data.sublines.filter((a: any) => a.lineCd == productService.productInfo.lineCd && a.sublineCd == productService.productInfo.sublineCd)[0]?.sublineTime;
        let sublineTimeArr = this.sublineTime.split(':');
        let cutOff = new Date();
        cutOff.setHours(sublineTimeArr[0], sublineTimeArr[1], sublineTimeArr[2]);
        let isPastCutOff = new Date() > cutOff;
        // IGNORE CUTOFF IF SUBLINE_TIME_TAG = "N" 
        let ignoreCutOff = sublineTimeTag === "N";
        allowDateRange = (typeof allowDateRange == 'number') ? allowDateRange : 1;

        //let sameDayUser: String = this.jsonDataService.data.plans.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd && a.sublineCd == this.productService.productInfo.sublineCd && a.planCd == this.productService.productInfo.planInfo.planCd)[0]?.sameDayUser;
        let isSameDayUser: Boolean = allowDateRange <= 0;
        this.sameDayTag = isSameDayUser && (ignoreCutOff || !isPastCutOff);
        this.minEffDate = new Date();
        this.minEffDate.setHours(0, 0, 0);
        if(allowDateRange == 0 && !this.sameDayTag){
            allowDateRange = 1;
        }
        let defaultDate = new Date();
        defaultDate.setTime(this.minEffDate.getTime() + ( (!this.sameDayTag && allowDateRange > 0) ? 86400000 : 0) );
        this.minEffDate.setTime(this.minEffDate.getTime() + (allowDateRange *  86400000));
        
        this.maxEffDate = new Date();
        this.maxEffDate.setTime(defaultDate.getTime() + (86400000 * this.effDateRangeDays)); // default : 2592000000

        this.coverageInfo.get('effDate')?.setValue(this.productService.productInfo.coverageInfo?.effDate || defaultDate);
        this.disableShowCompBtn = !this.productService.productInfo.coverageInfo?.isAllowed;
        this.productService.productInfo.coverageInfo?.disableEffDate && this.coverageInfo.get('effDate')?.disable();
        this.coverageInfo.markAsPristine();

        this.policyHeaderDetailsForm.disable();

        this.editablePD = parameterService.paramV("DIFF_VTPL_SI") == 'Y';

        setTimeout((e: any) =>
            popupService.popup.open('CO'), 0);
        productService.productInfo?.prodVouchInfo?.agentCd && this.coverageInfo.get('agentDetail')?.disable();
    }

    ngOnInit(): void {
        this.getDataJson();
        if((this.productService.productInfo.reEffDateFlag || this.productService.productInfo.recomputeFlag || (this.productService.productInfo.creationProcess === 'quotation' && this.productService.productInfo.quoteStatCd === 1 && this.productService.productInfo.recordId)) && this.productService.productInfo.lineCd == 'MC' ){
            this.getEffDateParams();
        }else if(this.productService.productInfo.sublineCd == 'CTPL' && this.productService.productInfo.vehicleInfo.vehicleType == '2'){
            this.coverageInfo.get('effDate')?.disable();
            this.maxEffDate = this.productService.productInfo.coverageInfo?.effDate;
            this.continueOnInit();
        }else{
            this.disableShowCompBtn = false;
            this.continueOnInit();
        }

        if (this.productService.productInfo.sublineCd == 'COM') {
            this.fbAnalyticsService.trackEvent('Motor_Quotation_Coverage')
        } else if (this.productService.productInfo.sublineCd == 'CTPL') {
            this.fbAnalyticsService.trackEvent('Liability_ Quotation_Coverage')
        } else if (this.productService.productInfo.sublineCd == 'FPA') {
            this.fbAnalyticsService.trackEvent('Family_ Quotation_ Coverage')
        } else if (this.productService.productInfo.sublineCd == 'IND') {
            this.fbAnalyticsService.trackEvent('Personal_Quotation_ Coverage')
        } else if (this.productService.productInfo.sublineCd == 'HCA') {
            this.fbAnalyticsService.trackEvent('Home_Quotation_Coverage')
        }
    }

    //functions
    continueOnInit(): void {
        this.getExpiry();

        if (this.productService.productInfo.coverages && this.productService.productInfo.coverages?.length !== 0) {
            this.haveData = true;
            this.stepperDtlService.stepperDtlBtns[2].isShow = String(this.haveData);
            this.stepperDtlService.stepperDtlBtns[1].isShow = String(this.haveData && this.hasAccount);
        }

        // ? Old : if (this.productService.productInfo?.coverageInfo?.covCompTag && this.productService.productInfo?.coverageInfo?.covCompTag != (this.userDetails.accountType || 'P')) {
        // * New : Validation should only appear if BMT018.COV_COMP_TAG <> BMM013.USER_TYPE of the BMT018.CREATE_USER.
        if (this.productService.productInfo?.coverageInfo?.covCompTag && this.productService.productInfo?.coverageInfo?.covCompTag != (this.productService.productInfo?.coverageInfo?.userTypeOfCreateUser || (this.userDetails.accountType || 'P'))) {
            let userType = this.jsonDataService.data.refCds.filter((a: any) => a.rvDomain == 'BMM013.USER_TYPE' && a.rvLowValue == this.userDetails.accountType)[0].rvMeaning;
            this.dialogBoxService.open({
                messageType: "I",
                content: `You are currently logged in as ${userType}. Your coverage will be re-computed based on your Account Type.`,
                buttons: [{
                    text: "Ok",
                    action: () => {
                        this.initPolicyHeaders();
                        this.showComputation();
                    }
                }]
            }, true);
        }

        if (this.userDetails.accountType === 'A') {
            this.coverageInfo.get('agentDetail')?.disable();
            this.coverageInfo.get('bmaDetail')?.disable();
            //this.coverageInfo.get('clientTypeGroupDetail')?.disable();
            if(this.agentType == 'D'){
                this.policyHeaderDetailsForm.enable();
            }
        }
        else if (this.userDetails.accountType === 'E') {
            this.policyHeaderDetailsForm.enable();
        }

        this.dropDownChanges();
        this.depWithPremField();

        // ? check if user logged in can edit client type
        if (this.editableClientType == 'Y') {
            this.coverageInfo.get('clientTypeGroupDetail')?.enable();
        }
        else {
            this.coverageInfo.get('clientTypeGroupDetail')?.disable();
        }

        // ? initialize some dropdowns based on user logged in
        this.initPolicyHeaders();
        this.m093 = this.jsonDataService.data.userType.filter((a: any) => a.userType == (this.userDetails.accountType || 'P'))[0];
        if (this.lineCd === "MC") {
            let today = new Date();
            this.m037 = this.jsonDataService.data.perilAllowedTsi.filter((a: any) => a.lineCd == this.lineCd && a.carTypeCd == 1);
            this.m094 = this.jsonDataService.data.siLimit.filter((a: any) => a.userType == this.userDetails.accountType && a.userLevel == this.userDetails.userLevel && a.lineCd == this.lineCd && a.itemTypeCd == this.productService.productInfo.vehicleInfo.vehicleType);
            this.fmv = this.jsonDataService.data.veDetailsOSP.filter((a: any) =>
                this.productService.productInfo.vehicleInfo.yearModelDetail.value == a.modelYear &&
                this.productService.productInfo.vehicleInfo.makeDetail.value == a.carCompanyCd &&
                this.productService.productInfo.vehicleInfo.modelDetail.value == a.makeCd &&
                this.productService.productInfo.vehicleInfo.subModelDetail.value == a.seriesCd 
                && today > new Date(a.effDateFrom)
                && (!a.effDateTo || today < new Date(a.effDateTo))
            )[0];
            let m072Dtls = this.jsonDataService.data.agentGrps.find((a: any) => a.agentGrp == this.userDetails.agentGrp);
            this.displayedColumns = ["perilName", "amountCovered", "premAmount"];

            if (this.m093?.hidePremRtTag == 'N') {
                this.displayedColumns.splice(2, 0, "premRt");
            }

            if (this.userDetails.accountType !== 'P') {
                this.displayedColumns.push("serviceFeeRt", "serviceFeeAmt");
            }
            

            if (this.userDetails.accountType === 'A') {
                if ((m072Dtls && m072Dtls?.hideCommTag == "N") ||
                    (!m072Dtls?.hideCommTag && this.m093?.hideCommTag == 'N')) {
                    this.displayedColumns.push('commRt');
                    this.displayedColumns.push('commAmt');
                }
            }
            else {
                if (this.m093?.hideCommTag == 'N') {
                    this.displayedColumns.push('commRt');
                    this.displayedColumns.push('commAmt');
                }
            }
        }

        if (["PA", "CI"].includes(this.lineCd) && this.productService.productInfo.planInfo.referralCode) {
            this.coverageInfo.patchValue({
                promoCode: this.productService.productInfo.planInfo.referralCode
            });
        }

        if (this.lineCd === 'HA') {
            let m072Dtls = this.jsonDataService.data.agentGrps.find((a: any) => a.agentGrp == this.userDetails.agentGrp);
            this.displayedColumns = ["itemNo", "perilName", "amountCovered", "premAmount"];
            if (this.m093?.hidePremRtTag == 'N') {
                this.displayedColumns.splice(3, 0, "premRt");
            }

            if (this.userDetails.accountType === 'A') {
                if ((m072Dtls && m072Dtls?.hideCommTag == "N") ||
                    (!m072Dtls?.hideCommTag && this.m093?.hideCommTag == 'N')) {
                    this.displayedColumns.push('commRt');
                    this.displayedColumns.push('commAmt');
                }
            }
            else {
                if (this.m093?.hideCommTag == 'N') {
                    this.displayedColumns.push('commRt');
                    this.displayedColumns.push('commAmt');
                }
            }
            let {country, provOrCity, postCode} = this.productService.productInfo.propertyInfo || {};
            let m092 = this.jsonDataService.data.city.filter((c:any)=> (c.cityCd == (provOrCity?.value || postCode?.cityCd)) && (c.countryCd == country?.value) && (c.provinceCd == (postCode.provinceCd || postCode.provCd || provOrCity.provinceCd)))[0];
            let m047 = this.jsonDataService.data.province.filter((c:any)=>(c.countryCd == country?.value) && (c.provinceCd == (postCode.provinceCd || postCode.provCd || provOrCity.provinceCd)))[0];
            this.eqZoneCd = m092?.eqZoneCd || m047?.eqZoneCd;
            this.flZoneCd = m092?.floodZoneCd || m047?.floodZoneCd;
            this.tyZoneCd = m092?.typhoonZoneCd || m047?.typhoonZoneCd;
            this.zoneCd = m092?.zoneCd || m047?.zoneCd;
        }

        // ? if productInfo has existing coverages from db
        if (this.productService.productInfo.coverages && this.productService.productInfo.coverages?.length !== 0) {
            this.coverages = this.productService.productInfo.coverages;

            if (!this.coverages[this.coverages.length - 1].perilCd) {
                this.coverages.pop();
            }

            this.coverages.push({
                perilName: 'Total',
                tsiAmt: null,
                premRt: null,
                premAmt: this.productService.productInfo.perilInfo.premAmt,
                commAmt: this.productService.productInfo.perilInfo.comAmount,
                serviceFeeAmt: this.productService.productInfo.perilInfo.serviceFeeAmt
            });
            this.coverageInfo.patchValue(this.productService.productInfo.coverageInfo);
            this.coverageInfo.patchValue({ quoteId: this.quoteId });

            if (this.lineCd === "MC")
                this.mergePerilfromMtn.MC(this.coverages);

            if (this.lineCd === "HA") {
                this.mergePerilfromMtn.HA(this.coverages);
            }

            if(this.lineCd !== "HA") {
                this.dataSource = new MatTableDataSource(this.coverages);
                this.dataSource.sort = this.sort;
            }

            this.policyHeader = this.productService.productInfo.policyHeaderDetails;
            this.perilInfo = this.productService.productInfo.perilInfo;
            this.deductibles = this.productService.productInfo.deductibles;

            if (this.lineCd === "MC") {
                this.perilInfo.comAmount = this.productService.productInfo.ara.map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b.commAmt);
                this.perilInfo.commRt = (this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                this.ara = this.productService.productInfo.ara;
                this.peril = this.productService.productInfo.peril;
                this.taxes = this.productService.productInfo.taxes;
            }

            if (["PA", "CI"].includes(this.lineCd)) {
                if (!this.perilInfo.comAmount)
                    this.perilInfo.comAmount = +this.productService.productInfo.coverages.map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b).toFixed(2);
                this.perilInfo.commRt = Math.round(this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                this.coverages = this.productService.productInfo.coverages;
                this.taxes = this.productService.productInfo.taxes;
                this.benefitsCoverages = this.productService.productInfo.benefitsCoverages;
            }

            if (this.lineCd === "HA") {
                this.perilInfo.comAmount = this.productService.productInfo.coverages.map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b);
                this.perilInfo.commRt = Math.round(this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                this.coverages = this.productService.productInfo.coverages;
                this.coverages[this.coverages.length - 1].commAmt = 0;
                this.coverages.map((e: any) => {
                    if(e?.itemNo) {
                        this.coverages[this.coverages.length - 1].commAmt += e.commAmt;
                    }
                });
                this.taxes = this.productService.productInfo.taxes;
            }

            if (this.summarizedPremTag === 'Y') {
                this.setPremiumCharges();
                this.productService.setProductInfo("premiumCharges", this.premiumCharges);
            }
        } else if (this.productService.productInfo.recomputeFlag) {
            this.productService.setProductInfo('recomputeFlag', '');
            if (this.summarizedPremTag === 'Y')
                this.productService.setProductInfo('premiumCharges', null);
            this.showComputation();
        }

        // ? first time doing coverage, automatic show computation on page
        if (this.summarizedPremTag === 'Y' && !this.quoteId && (["PA", "CI"].includes(this.lineCd)) && !this.coverages) {
            this.showComputation();
        }
        this.minPremTag = this.productService.productInfo.coverageInfo?.minPremTag == 'Y';


        if(this.lineCd === "HA") {
            this.dataSource = new MatTableDataSource(this.coverages);
            this.dataSource.sort = this.sort;
        }
    }

    getDataJson(): void {
        this.contractType = [
            { Id: 1, name: "Dual Purpose Body" },
            { Id: 2, name: "Private Car" }
        ];
        this.noOfYears = this.jsonDataService.data.refCds.filter((a: any) => a['rvDomain'] == 'BMT018.NO_OF_YEARS').map((a: any) => { return { desc: a.rvMeaning, value: a.rvLowValue } });

        if (!this.coverageInfo.get('noOfYears')?.value) {
            this.coverageInfo.get('noOfYears')?.setValue(1, { emitEvent: false });
        }

        if (this.jsonDataService.data.params.filter((a: any) => a.paramName == 'MULTI_YEAR')[0].paramValueV == 'N') {
            this.coverageInfo.get('noOfYears')?.disable({ onlySelf: true, emitEvent: false });
        }

        if (this.lineCd === "CI") {
            const noOfDays = this.jsonDataService.data.BMM118.filter((a: any) => (a.userType === (this.userDetails.accountType || "P") && a.lineCd === this.lineCd && a.sublineCd === this.productService.productInfo.sublineCd && a.planCd === this.productService.productInfo.planInfo.planCd))[0]?.noOfDays;
            if (noOfDays) {
                this.coverageInfo.get('noOfDays')?.setValue(noOfDays, { emitEvent: false });
            }

            if (this.editableNoOfDaysParam !== this.userDetails.accountType) {
                this.coverageInfo.get('noOfDays')?.disable(); //Temporary disabled
            }
        }
    }

    public move(action: string): void {
        // ? button for back
        if (action === 'back') {
            if (this.coverageInfo.dirty || (this.userDetails.accountType === 'E' && this.policyHeaderDetailsForm.dirty)) {
                this.showConfirm(action);
            }
            else {
                this.proceedMove(action);
            }
        }
        // ? button for next
        else {
            // ? if user is not logged in
            if (!this.userDetails.name) {
                if (this.coverageInfo.get('effDate')?.errors && action === 'next') {
                    this.appMessageService.showAppMessage("Date input is not valid.", "error");
                    return;
                } else if (this.coverageInfo.get('effDate')?.errors && action === 'back') {
                    this.proceedMove(action);
                    return;
                }
                if (this.computeDependencies.some(e=>this.coverageInfo.get(e)?.dirty)) {
                    this.appMessageService.showAppMessage("Your recent changes require re-calculation of amounts. Please click Show Computation button.", "info");
                    return;
                }
                this.moveToStorage();
            }
            // ? if user is logged in
            else {
                if (this.coverageInfo.dirty || this.showComputationIsClicked) {
                    this.appMessageService.showAppMessage("Please save coverage details.", "error");
                    return;
                }
                if (this.lineCd === "MC") {
                    if (action == 'next' && (!(this.productService.productInfo.ara && this.productService.productInfo.ara.length != 0) || this.productService.productInfo.coverageInfo.covCompTag != (this.userDetails.accountType || 'P'))) {
                        this.appMessageService.showAppMessage("Please save coverage details.", "error");
                        return;
                    }
                    // else if (action === "next" && !this.productService.productInfo.quoteNo && (this.minEffDate > new Date(this.productService.productInfo.coverageInfo.effDate) || this.maxEffDate < new Date(this.productService.productInfo.coverageInfo.effDate))) {
                    //     this.appMessageService.showAppMessage(`1 Unable to proceed. Effective Date must be within ${this.effDateRangeDays} days starting from ${this.sameDayTag ? 'today' : 'the next day'}.`, "error");
                    //     return;
                    // }
                } else if (["PA", "CI"].indexOf(this.lineCd) >= 0) {
                    if (action == 'next' && (!this.productService.productInfo.premiumCharges || this.productService.productInfo?.coverageInfo?.covCompTag != (this.productService.productInfo?.coverageInfo?.userTypeOfCreateUser || (this.userDetails.accountType || 'P')))) {
                        this.appMessageService.showAppMessage("Please save coverage details.", "error");
                        return;
                    } else if (action == 'next' && (!this.productService.productInfo.quoteNo && (this.minEffDate > new Date(this.productService.productInfo.coverageInfo.effDate) || this.maxEffDate < new Date(this.productService.productInfo.coverageInfo.effDate)))) {
                        this.appMessageService.showAppMessage(`Unable to proceed. Effective Date must be within ${this.effDateRangeDays} days starting from ${this.sameDayTag ? 'today' : 'the next day'}.`, "error");
                        return;
                    }
                } else if (this.lineCd === "HA") {
                    if (action == 'next' && (!(this.productService.productInfo.coverages && this.productService.productInfo.coverages.length != 0) || this.productService.productInfo?.coverageInfo?.covCompTag != (this.productService.productInfo?.coverageInfo?.userTypeOfCreateUser || (this.userDetails.accountType || 'P')))) {
                        this.appMessageService.showAppMessage("Please save coverage details.", "error");
                        return;
                    } else if (action == 'next' && (!this.productService.productInfo.quoteNo && (this.minEffDate > new Date(this.productService.productInfo.coverageInfo.effDate) || this.maxEffDate < new Date(this.productService.productInfo.coverageInfo.effDate)))) {
                        this.appMessageService.showAppMessage(`Unable to proceed. Effective Date must be within ${this.effDateRangeDays} days starting from ${this.sameDayTag ? 'today' : 'the next day'}.`, "error");
                        return;
                    }
                }
            }

            // ? to proceed (if user is logged in and has unsaved changes, display confirm to move)
            if (this.userDetails.name && (this.tableForm.dirty || this.coverageInfo.dirty || (this.userDetails.accountType === 'E' && this.policyHeaderDetailsForm.dirty))) {
                this.showConfirm(action);
            } else {
                this.proceedMove(action);
            }
        }
    }

    proceedMove(action: String) {
        if (action === "next") {
            if (["MC", "HA"].indexOf(this.lineCd) >= 0) {
                this.router.navigate(["/quotation/review-premium-charges"]);
            }
            else if (["PA", "CI"].indexOf(this.lineCd) >= 0) {
                if (this.userDetails.userId) {
                    this.router.navigate(["/quotation/personal-details"]);
                } else {
                    this.router.navigate(["/quotation/account"]);
                }
            }
        } else {
            if (["PA", "CI"].indexOf(this.lineCd) >= 0) {
                this.router.navigate(["/quotation/choose-plan"]);
            }
            else if (this.lineCd === "MC") {
                this.router.navigate(["/quotation/vehicle-details"]);
            }
            else if (this.lineCd === "HA") {
                this.router.navigate(["/quotation/property-details"]);
            }
        }
    }

    showConfirm(action: String): void {
        let content = (this.userDetails.name) ? "You have unsaved changes. Do you want to continue?" : "You have changes. Do you want to continue?";
        this.dialogBoxService.open({
            messageType: "C",
            content: content,
            buttons: [
                { text: "No" },
                {
                    text: "Yes", action: () => {
                        this.proceedMove(action);
                    }
                }
            ]
        });
    }

    stepperCheck() {
        if (this.lineCd === "MC")
            return {
                number: 3,
                label: "Next: Premium",
                title: "Coverage / Perils",
            };
        if (["PA", "CI"].includes(this.lineCd))
            return {
                number: 2,
                label: "Next: Personal",
                title: "Coverage and Charges",
            };
        if (this.lineCd === "HA")
            return {
                number: 3,
                label: "Next: Premium",
                title: "Coverage / Perils",
            };
        return {
            number: 0,
            label: "Next:",
            title: "Null",
        };
    }

    private depWithPremField(): void {
        if (this.maxDepWithPrem > 0) {
            this.coverageInfo.controls['depWithPrem'].valueChanges.subscribe((e: any) => {
                let dependentsT22 = this.productService.productInfo.dependents?.length ?? 0;
                //e = e.replaceAll(' ','');
                if (e < 0 || isNaN(e) || (e ?? '') === '' || !Number.isInteger(+e)) {
                    this.coverageInfo.controls['depWithPrem'].setErrors({ 'incorrect': true });
                    this.depWithPremError = 'Invalid input. Please enter a valid value.';
                }
                else if (+e > this.maxDepWithPrem) {
                    this.coverageInfo.controls['depWithPrem'].setErrors({ 'incorrect': true });
                    this.depWithPremError = `The maximum number of dependents for this plan is ${this.maxDepWithPrem}.`;
                }
                else if (dependentsT22 && +e === 0) {
                    this.clearDependentsPrompt();
                    this.coverageInfo.controls['depWithPrem'].setErrors(null);
                    this.depWithPremError = '';
                }
                else if (dependentsT22 && +e < dependentsT22) {
                    this.coverageInfo.controls['depWithPrem'].setErrors({ 'incorrect': true });
                    this.depWithPremError = `To enter this value, please go to Personal Details screen and update the list of dependents first.`;
                }
                else {
                    this.coverageInfo.controls['depWithPrem'].setErrors(null);
                    this.depWithPremError = '';
                }
            });
        }
    }

    private clearDependentsPrompt(): void {
        let content = "This will automatically delete the list of dependents specified in the Personal Details screen. Do you want to proceed?";
        this.dialogBoxService.open({
            messageType: "C",
            content: content,
            buttons: [
                {
                    text: "No", action: () => {
                        this.revertDependentValue();
                    }
                },
                {
                    text: "Yes", action: () => {
                        this.clearDependents();
                    }
                }
            ]
        });
    }

    private revertDependentValue(): void {
        this.coverageInfo.patchValue({
            depWithPrem: this.productService.productInfo?.coverageInfo?.depWithPrem ?? 0
        }, {
            emitEvent: false
        });
    }

    private clearDependents(): void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.deleteDependents({
            quoteId: this.quoteId
        }).subscribe({
            next: (response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.productService.setProductInfo('dependents', []);
                        this.productService.setProductInfo('dependentsEdit', []);
                        this.appMessageService.showAppMessage("Successfully deleted.", "success");
                    } else {
                        this.appMessageService.showAppMessage("Error deleting dependents.", "error");
                    }
                    this.jsonDataService.contorlLoading(false);
                });
            },
            error: (e: any) => {
                this.securityService.checkRequestKeyResponse(e, () => {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Error deleting dependents.", "error");
                });
            }
        });
    }

    private dropDownChanges(): void {
        // ? check if agentDetail is changed (applicable to usertype = E only)
        this.coverageInfo.get('agentDetail')?.valueChanges.subscribe((e: any) => {
            this.agentChanged(e);
        });
        // ? if sourceExtDetail changed, filter referror code acc to it (applicable to usertype = E only)
        this.coverageInfo.get('sourceExtensionDetail')?.valueChanges.subscribe((e: any) => {
            // do nothing yet
        });
        this.coverageInfo.get('referrorCodeDetail')?.valueChanges.subscribe((e: any) => {
            // do nothing yet
        });
    }

    private initPolicyHeaders() {
        if (['A', 'E', 'B'].includes(this.userDetails.accountType)) {
            this.coverageInfo.patchValue({ promoCode: '' });
        }

        if (this.prodVouchCdExist) {
            this.coverageInfo.patchValue({ promoCode: this.productService.productInfo.prodVouchInfo?.prodVouchCd ?? '' });
            this.coverageInfo.get('clientTypeGroupDetail')?.disable();
        }

        if (['A', 'E', 'B', 'P'].includes(this.userDetails.accountType) && this.quoteId) {
            this.defaultInitDropdowns();
        }

        if (['MC', 'HA'].includes(this.lineCd)) {
            if (this.prodVouchCdExist && (!this.userDetails.userId && !this.quoteId)) {
                // ? if not loggedin user
                this.initDropdownsShown();
            }
            if ((!(this.productService.productInfo.coverages?.length > 0)) || this.productService.productInfo.recomputeFlag) {
                this.initDropdownsShown();
            }
        }

        if (['PA', 'CI'].includes(this.lineCd)) {
            if (this.prodVouchCdExist || (['A', 'E', 'B'].includes(this.userDetails.accountType) && !this.quoteId)) {
                this.initDropdownsShown();
            }
        }
    }

    private initDropdownsShown() {
        let policyHeaderCds: any;
        const usersDtl = this.jsonDataService.data.users.find((a: any) => a.userId === this.userDetails.userId);
        const userTypeDtl = this.jsonDataService.data.userType.find((a: any) => a.userType === this.userDetails.accountType);
        if (this.userDetails.accountType === 'A') {
            const agentLoggedIn = this.jsonDataService.data.agent.find((a: any) => a.agentCd === this.userDetails.userId);
            policyHeaderCds = {
                agentCd: this.userDetails.userId,
                bmaCd: agentLoggedIn.bmaCd,
                clientCd: userTypeDtl.clientCd,
            }
        }
        if (this.userDetails.accountType === 'B') {
            policyHeaderCds = {
                clientCd: userTypeDtl.clientCd
            }
        }
        if (this.userDetails.accountType === 'E') {
            policyHeaderCds = {
                agentCd: userTypeDtl.agentCd,
                bmaCd: usersDtl.bmaCd,
                clientCd: userTypeDtl.clientCd,
                srcCd: usersDtl.srcCd,
                srcExtCd: usersDtl.srcExtCd,
                referrorCd: usersDtl.referrorCd,
                projectCd: userTypeDtl.projectCd,
            }
        }

        if (this.prodVouchCdExist) {
            let defAgentCd = policyHeaderCds?.agentCd;
            policyHeaderCds = { ...this.productService.productInfo.prodVouchInfo };
            policyHeaderCds.agentCd = policyHeaderCds.agentCd || defAgentCd;
        }

        const agentFull = this.jsonDataService.data.agent.find((a: any) => a.agentCd === policyHeaderCds?.agentCd);
        const agentDetail = agentFull ? {
            value: agentFull?.agentCd || "",
            text: agentFull ? (agentFull?.bmCode + " - " + agentFull?.agentName) : "",
        } : "";
        const bmaFull = this.jsonDataService.data.bma.find((a: any) => a.bmaCd == policyHeaderCds?.bmaCd);
        const bmaDetail = bmaFull ? {
            value: bmaFull?.bmaCd || "",
            text: bmaFull?.bmaDesc || "",
        } : "";
        const clientFull = this.jsonDataService.data.client.find((a: any) => a.clientCd === policyHeaderCds?.clientCd);
        const clientDetail = clientFull?.bmCode;
        this.clientTypeBmCode = clientDetail;
        const srcFull = this.jsonDataService.data.src.find((a: any) => a.srcCd == policyHeaderCds?.srcCd);
        const srcDetail = srcFull ? {
            value: srcFull?.srcCd || "",
            text: srcFull?.srcCdDesc || "",
        } : "";
        const srcExtFull = this.jsonDataService.data.srcExt.find((a: any) => a.srcExtCd == policyHeaderCds?.srcExtCd);
        const srcExtDetail = srcExtFull ? {
            value: srcExtFull?.srcExtCd || "",
            text: srcExtFull?.srcExtDesc || "",
        } : "";
        const referrorFull = this.jsonDataService.data.referror.find((a: any) => a.referrorCd == policyHeaderCds?.referrorCd);
        const referrorDetail = referrorFull ? {
            value: referrorFull?.referrorCd || "",
            text: referrorFull?.referrorDesc || "",
        } : "";
        const projectFull = this.jsonDataService.data.project.find((a: any) => a.projectCd == policyHeaderCds?.projectCd);
        const projectDetail = projectFull ? {
            value: projectFull?.projectCd || "",
            text: projectFull ? (projectFull?.bmCode ? projectFull?.bmCode + " - " : "") + projectFull?.projectDesc : "",
        } : "";
        this.coverageInfo.patchValue({
            agentDetail: this.productService.productInfo.policyHeaderDetails?.agentDetail || agentDetail,
            bmaDetail: bmaDetail,
            clientTypeGroupDetail: clientDetail,
        });
        this.policyHeaderDetailsForm.patchValue({
            sourceCodeDetail: srcDetail,
            sourceExtensionDetail: srcExtDetail,
            referrorCodeDetail: referrorDetail,
            projectCodeDetail: projectDetail,
        });
    }

    private defaultInitDropdowns() {
        // ? client grp initialize:
        const clientType = this.jsonDataService.data.client.find((a: any) => a.clientCd === this.productService.productInfo.policyHeaderDetails?.clientTypeGroupDetail?.value);
        this.clientTypeBmCode = clientType?.bmCode;
        this.coverageInfo.patchValue({
            agentDetail: this.productService.productInfo.policyHeaderDetails?.agentDetail,
            bmaDetail: this.productService.productInfo.policyHeaderDetails?.bmaDetail,
            clientTypeGroupDetail: clientType?.bmCode,
        });
        this.policyHeaderDetailsForm.patchValue({
            sourceCodeDetail: this.productService.productInfo.policyHeaderDetails?.sourceCodeDetail,
            sourceExtensionDetail: this.productService.productInfo.policyHeaderDetails?.sourceExtensionDetail,
            referrorCodeDetail: this.productService.productInfo.policyHeaderDetails?.referrorCodeDetail,
            projectCodeDetail: this.productService.productInfo.policyHeaderDetails?.projectCodeDetail
        });
    }

    moveToStorage() {
        if (this.promoError) {
            this.coverageInfo.patchValue({ promoCode: '' });
            this.promoError = '';
            if (this.prodVouchCdExist) {
                this.coverageInfo.patchValue({ promoCode: this.productService.productInfo.prodVouchInfo?.prodVouchCd ?? '' });
            }
        }
        this.productService.productInfo.prodVouchInfo.prodVouchCd = this.coverageInfo.get('promoCode')?.value;
        if (this.summarizedPremTag === 'Y') {
            this.productService.setProductInfo("premiumCharges", this.premiumCharges);
        }
        this.productService.setProductInfo("perilInfo", this.perilInfo);
        this.productService.setProductInfo("coverages", this.coverages);
        this.productService.setProductInfo("ara", this.ara);
        this.productService.setProductInfo("peril", this.peril);
        this.productService.setProductInfo("taxes", this.taxes);
        this.productService.setProductInfo("benefitsCoverages", this.benefitsCoverages);
        let covInfo = this.coverageInfo.getRawValue();
        covInfo.minPremTag  = this.minPremTag ? 'Y' : 'N';
        this.productService.setProductInfo("coverageInfo", covInfo);
        this.productService.setProductInfo("deductibles", this.deductibles);
        this.policyHeaderSet();
        this.coverageInfo.get('conType')?.value && this.productService.setContractType(this.coverageInfo.get('conType')?.value);
    }

    policyHeaderSet() {
        // ? bmCode of clientType to clientCd + clientDesc
        // // const clientTypeBmCode = this.coverageInfo.get('clientTypeGroupDetail')?.value;
        if (this.clientTypeError) {
            this.clientTypeError = '';
        }
        const clientTypeBmCode = this.clientTypeBmCode;
        const clientTypeGroup = this.jsonDataService.data.client.find((a: any) => a.bmCode === clientTypeBmCode);
        const clientTypeGroupDetail = { value: clientTypeGroup?.clientCd, text: clientTypeGroup?.clientDesc };
        this.productService.setProductInfo("policyHeaderDetails", {
            ...this.policyHeaderDetailsForm.value,
            agentDetail: this.coverageInfo.get('agentDetail')?.value,
            bmaDetail: this.coverageInfo.get('bmaDetail')?.value,
            clientTypeGroupDetail: clientTypeGroupDetail
        });
    }

    getExpiry(): void {
        if (this.coverageInfo.getRawValue().noOfYears !== null) {
            if (isNaN(Date.parse(this.coverageInfo.getRawValue().effDate))) {
                this.coverageInfo.patchValue({
                    expDate: null,
                });
                return;
            }
            let effDate = new Date(this.coverageInfo.getRawValue().effDate);
            effDate = new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + effDate.getFullYear() + " " + this.sublineTime);
            if (this.productService.productInfo.sublineCd == 'CTPL') {
                let years = this.jsonDataService.data.mvPremType.filter((a: any) => a.mvPremType == this.productService.productInfo.vehicleInfo?.mvPremTypeDetail?.value)[0]?.noOfYears;
                this.coverageInfo.patchValue({
                    expDate: new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + (effDate.getFullYear() + years) + " " + this.sublineTime),
                });
            } else {
                this.coverageInfo.patchValue({
                    expDate: new Date(effDate.getMonth() + 1 + "/" + effDate.getDate() + "/" + (effDate.getFullYear() + this.coverageInfo.getRawValue().noOfYears) + " " + this.sublineTime),
                });
            }
        }
    }

    private getClassCd(): string {
        //temp for now
        let classCd = "";
        let { roof, wall } = this.productService.productInfo?.propertyAssessment;
        classCd = (roof?.classCd === 'A' && wall?.classCd === 'A') ? roof?.classCd : ""
        return classCd;
    }

    getSelectedPeril(row: object): void {
        this.selectedPeril = row as any;
    }

    showComputation(data?: any) {
        this.compute(data);
        // this.formService.showFormLoader(this, "coverage-perils-form", "Computing Coverage / Perils.<br>Please wait ...", "compute",data);
    }

    compute(data?: any) {
        if(this.disableShowCompBtn){
            return;
        }

        if (this.lineCd === "CI") {
            if (!this.coverageInfo.get('noOfDays')?.value) {
                // only noOfDays yung required
                const message = "Maximum number of days is required.";
                this.appMessageService.showAppMessage(message, "error");
                return;
            }
            else if (isNaN(this.coverageInfo.get('noOfDays')?.value)) {
                // only noOfDays yung required
                const message = "Maximum number of days must be a number.";
                this.appMessageService.showAppMessage(message, "error");
                return;
            }
            else if (this.coverageInfo.get('noOfDays')?.value < 1) {
                // only noOfDays yung required
                const message = "Maximum number of days must be greater than 0.";
                this.appMessageService.showAppMessage(message, "error");
                return;
            }
        }

        if (this.userDetails.accountType === 'E') {
            if (this.formService.isInvalid(this.coverageInfo, 'agentDetail') ||
                this.formService.isInvalid(this.coverageInfo, 'bmaDetail') ||
                this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceCodeDetail') ||
                this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceExtensionDetail') ||
                this.formService.isInvalid(this.policyHeaderDetailsForm, 'referrorCodeDetail') ||
                this.formService.isInvalid(this.policyHeaderDetailsForm, 'projectCodeDetail')) {
                this.appMessageService.showAppMessage("Unable to compute because of errors.", "error");
                return;
            }

            if (!this.coverageInfo.get('agentDetail')?.value ||
                !this.coverageInfo.get('bmaDetail')?.value ||
                !this.coverageInfo.get('clientTypeGroupDetail')?.value ||
                !this.policyHeaderDetailsForm.get('projectCodeDetail')?.value) {
                this.appMessageService.showAppMessage("Fields are required to be filled.", "info");
                return;
            }

        }

        if (this.userDetails.accountType === 'A') {
            if (!this.coverageInfo.get('agentDetail')?.value ||
                !this.coverageInfo.get('bmaDetail')?.value ||
                !this.coverageInfo.get('clientTypeGroupDetail')?.value) {
                this.appMessageService.showAppMessage("Fields are required to be filled.", "info");
                return;
            }
        }

        if (this.editFlag) {
            this.toggleEdit();
        }
        let perils = data?.perils;
        let thenSave = data?.thenSave;

        if(data){
            this.coverageInfo.get('clientTypeGroupDetail')?.setValue(this.clientTypeBmCode)
        }

        // ? policyHeaderInfo
        let policyHeaderInfo = {
            ...this.policyHeaderDetailsForm.value,
            agentDetail: this.coverageInfo.get('agentDetail')?.value,
            bmaDetail: this.coverageInfo.get('bmaDetail')?.value,
            clientTypeGroupDetail: this.coverageInfo.get('clientTypeGroupDetail')?.value
        };

        let productInfo: any = {
            lineCd: this.productService.productInfo.lineCd,
            sublineCd: this.productService.productInfo.sublineCd,
            planCd: this.productService.productInfo.planInfo.planCd,
        };

        let vehicleInfo = this.productService.productInfo.vehicleInfo;

        

        let propertyInfo = (this.lineCd === "HA") ? {
            ...this.productService.productInfo.propertyInfo,
            zoneCd: this.zoneCd,
            classCd: this.getClassCd(),
            eqZoneCd: this.eqZoneCd,
            tyZoneCd: this.tyZoneCd,
            flZoneCd: this.flZoneCd
        } : null;

        if(this.lineCd == 'HA'){
            let bldgItems = this.productService.productInfo.bldgForm?.bldgItms?.filter((i:any)=> i.bldgAmt);
            let contItems = this.productService.productInfo.contForm?.contItms?.filter((i:any)=> i.contAmt);

            let contents = [];

            if(bldgItems || contItems){
                let itemNo = 0;
                contents = bldgItems.concat(contItems).sort((a:any,b:any)=>a.seqNo-b.seqNo).map((i:any)=>{
                    itemNo += 1;
                    return {
                        amt : i.bldgAmt || i.contAmt,
                        itmCd : i.bldgItmCd || i.contItmCd,
                        itemNo: itemNo
                    };
                });
            }else{
                contents = this.productService.productInfo.bldgCont
                    .sort((a:any,b:any)=>a.itemNo - b.itemNo)
                    .map((i:any)=>{
                        return {amt : i.bldgAmt,
                                itmCd : i.bldgItmCd,
                                itemNo: i.itemNo}
                    });
            }
            propertyInfo.contents = contents;
        }

        const coverageInfo = { ...this.coverageInfo.getRawValue(), userId: this.userDetails.userId };
        if (!(coverageInfo.effDate instanceof Date) && typeof coverageInfo.effDate !== 'string') {
            coverageInfo.effDate = coverageInfo.effDate._d;
        }

        if (typeof coverageInfo.effDate !== 'string') {
            coverageInfo.effDate = new Date(coverageInfo.effDate.getMonth() + 1 + "/" + coverageInfo.effDate.getDate() + "/" + coverageInfo.effDate.getFullYear() + " " + this.sublineTime);
            coverageInfo.expDate = new Date(coverageInfo.expDate.getMonth() + 1 + "/" + coverageInfo.expDate.getDate() + "/" + coverageInfo.expDate.getFullYear() + " " + this.sublineTime);
        } else if (typeof coverageInfo.effDate == 'string') {
            coverageInfo.effDate = new Date(coverageInfo.effDate);
            coverageInfo.expDate = new Date(coverageInfo.expDate);
        }
        if (!perils) {
            coverageInfo.conType = null;
        }

        this.jsonDataService.contorlLoading(true);
        
        let randomId = this.apiCallService.generateId();
        productInfo.randomId = randomId;
        this.apiCallService.computeCoveragePerils(
            [productInfo, vehicleInfo, policyHeaderInfo, coverageInfo, perils, propertyInfo]
        ).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {

                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        console.log({res});
                        if(res.randomId != randomId){
                            this.appMessageService.showAppMessage("We are unable to process your request. Please try again later.", "error");
                            return;
                        }

                        if (res.result === 'WRONG_PLAN_ERROR') {
                            this.promoError = res.promoError;
                            this.haveData = false;
                            return;
                        }

                        this.minPremTag = res[0][0].minPremTag == 'Y';

                        this.coverageInfo.patchValue({
                            tempId: res[0][0].tempId,
                            effDate: new Date(res[0][0].effDate),
                            expDate: new Date(res[0][0].expiryDate),
                            promoUserType: res[0][0].promoUserType,
                            promoAgentGrp: res[0][0].promoAgentGrp,
                            promoIssCd: res[0][0].promoIssCd,
                            conType: res[0][0].contractTypeCd,
                            covCompTag: res[0][0].covCompTag,
                            grpTag: res.grpTag
                        }, { emitEvent: false });

                        this.promoError = res.promoError;
                        this.clientTypeError = res.clientTypeError;

                        this.clientTypeBmCode = res.clientBm;

                        this.perilInfo = res[0][0];
                        this.deductibles = res[3];

                        if (this.lineCd === "MC") {
                            this.perilInfo.comAmount = res[2].map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b.commAmt);
                            this.perilInfo.commRt = (this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                        }

                        if (this.lineCd === "HA") {
                            this.perilInfo.comAmount = res[1].map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b);
                            this.perilInfo.commRt = (this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                        }

                        if (["PA", "CI"].includes(this.lineCd)) {
                            this.perilInfo.comAmount = +(res[1].map((a: any) => a.commAmt).reduce((a: number, b: any) => a + b)).toFixed(2);
                            this.perilInfo.commRt = Math.round(this.perilInfo.comAmount / this.perilInfo.totPremAmt) * 100;
                        }

                        this.haveData = true;
                        this.stepperDtlService.stepperDtlBtns[2].isShow = String(this.haveData);
                        this.stepperDtlService.stepperDtlBtns[1].isShow = String(this.haveData && this.hasAccount);

                        if (this.lineCd === "MC") {
                            this.coverages = res[1];
                            this.ara = res[2];
                            this.peril = res[3];
                            this.taxes = res[4];
                        }

                        if (this.lineCd === "HA") {
                            this.coverages = res[1];
                            this.taxes = res[2];
                        }

                        if (["PA", "CI"].includes(this.lineCd)) {
                            this.coverages = res[1];
                            this.taxes = res[2];
                            this.benefitsCoverages = res[3];
                        }

                        this.policyHeader = policyHeaderInfo;

                        this.coverages.push({
                            perilName: 'Total',
                            tsiAmt: null,
                            premRt: null,
                            premAmt: this.perilInfo.premAmt,
                            commAmt: this.perilInfo.comAmount,
                            serviceFeeAmt: this.perilInfo.serviceFeeAmt
                        });

                        if (this.lineCd === "MC")
                            this.mergePerilfromMtn.MC(this.coverages);

                        if (this.lineCd === "HA") {
                            this.mergePerilfromMtn.HA(this.coverages);
                        }

                        if (this.summarizedPremTag === 'N') {
                            this.dataSource = new MatTableDataSource(this.coverages);
                        }

                        this.tableForm.markAsDirty();

                        this.clientTypeGrpChanged({ value: this.jsonDataService.data.client.find((a: any) => a.bmCode === res.clientBm)?.clientCd });

                        // ? for making forms pristine
                        this.coverageInfo.get('effDate')?.markAsPristine();
                        this.coverageInfo.get('promoCode')?.markAsPristine();
                        this.coverageInfo.get('agentDetail')?.markAsPristine();
                        this.coverageInfo.get('bmaDetail')?.markAsPristine();
                        this.coverageInfo.get('clientTypeGroupDetail')?.markAsPristine();
                        this.coverageInfo.get('depWithPrem')?.markAsPristine();
                        if (this.lineCd == 'CI') {
                            this.coverageInfo.get('noOfDays')?.markAsPristine();
                        }

                        this.showComputationIsClicked = true;

                        // ? set premium charges because it is summarized
                        if (this.summarizedPremTag === 'Y') {
                            this.setPremiumCharges();
                        }

                        if (thenSave) {
                            if (this.summarizedPremTag === 'N')
                                this.editFlag = false;
                            this.tableForm.markAsPristine();
                            this.saveCoverageInfo();
                        }
                    });
                    console.log("Test", this.coverages);
                } else if (response.status === "APPROVAL_ERROR") {
                    this.dialogBoxService.open({
                        messageType: "I",
                        content: response.message,
                        buttons: [{
                            text: "Contact BPI MS",
                            action: () => {
                                const url = this.router.serializeUrl(
                                    this.router.createUrlTree(["/contact-us"], {
                                        queryParams: {
                                            brand: vehicleInfo.makeDetail.text,
                                            yearModel: vehicleInfo.yearModelDetail.value,
                                            model: vehicleInfo.modelDetail.text + " " + vehicleInfo.subModelDetail.text,
                                            srp: vehicleInfo.purchasePrice
                                        }
                                    })
                                );
                                this.router.navigateByUrl(url);
                            }
                        }, {
                            text: "Close"
                        }]
                    });

                } else {
                    const message = response.message === "SUCCESS" ? "We are unable to process your request. Please try again later." : response.message;
                    this.appMessageService.showAppMessage(message, "error");
                }
            });
        });
    }

    public saveCoverageInfo(): void {
        if (this.coverages[this.coverages.length - 1].premAmt <= 0) {
            this.appMessageService.showAppMessage("We are unable to save your coverage details. Please check the total premium amount of your quotation.", "error");
        } else {
            this.saveCoverage();
        }
    }

    requiredCheckBeforeSave() {
        // ? check if agent input is = PARAM_V(AGENT_CD) and then it means all fields are required to be filled
        if (this.checkerAgentCdParam()) {
            if (this.coverageInfo.get('agentDetail')?.value &&
                this.coverageInfo.get('bmaDetail')?.value &&
                this.coverageInfo.get('clientTypeGroupDetail')?.value &&
                this.policyHeaderDetailsForm.get('projectCodeDetail')?.value &&
                this.policyHeaderDetailsForm.get('sourceCodeDetail')?.value &&
                this.policyHeaderDetailsForm.get('sourceExtensionDetail')?.value &&
                this.policyHeaderDetailsForm.get('referrorCodeDetail')?.value &&
                !this.formService.isInvalid(this.coverageInfo, 'agentDetail') &&
                !this.formService.isInvalid(this.coverageInfo, 'bmaDetail') &&
                !this.formService.isInvalid(this.policyHeaderDetailsForm, 'projectCodeDetail') &&
                !this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceCodeDetail') &&
                !this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceExtensionDetail') &&
                !this.formService.isInvalid(this.policyHeaderDetailsForm, 'referrorCodeDetail'))
                return false;
            else
                return true;
        }
        // ? check if all fields that are required were filled
        if (this.coverageInfo.get('agentDetail')?.value &&
            this.coverageInfo.get('bmaDetail')?.value &&
            this.coverageInfo.get('clientTypeGroupDetail')?.value &&
            this.policyHeaderDetailsForm.get('projectCodeDetail')?.value &&
            !this.formService.isInvalid(this.coverageInfo, 'agentDetail') &&
            !this.formService.isInvalid(this.coverageInfo, 'bmaDetail') &&
            !this.formService.isInvalid(this.policyHeaderDetailsForm, 'projectCodeDetail') &&
            !this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceCodeDetail') &&
            !this.formService.isInvalid(this.policyHeaderDetailsForm, 'sourceExtensionDetail') &&
            !this.formService.isInvalid(this.policyHeaderDetailsForm, 'referrorCodeDetail'))
            return false;
        return true;
    }

    saveChecker() {
        // if (this.lineCd === "CI") {
        //     return this.coverageInfo.get('effDate')?.dirty ||
        //         this.coverageInfo.get('promoCode')?.dirty ||
        //         this.coverageInfo.get('noOfDays')?.dirty ||
        //         this.coverageInfo.get('agentDetail')?.dirty ||
        //         this.coverageInfo.get('bmaDetail')?.dirty ||
        //         this.coverageInfo.get('clientTypeGroupDetail')?.dirty ||
        //         !this.policyHeader;
        // }
        // return this.coverageInfo.get('effDate')?.dirty ||
        //     this.coverageInfo.get('promoCode')?.dirty ||
        //     this.coverageInfo.get('agentDetail')?.dirty ||
        //     this.coverageInfo.get('bmaDetail')?.dirty ||
        //     this.coverageInfo.get('clientTypeGroupDetail')?.dirty ||
        //     !this.policyHeader;
        return this.computeDependencies.some(e=>this.coverageInfo.get(e)?.dirty);
    }

    saveCoverage() {
        if (this.requiredCheckBeforeSave() && this.userDetails.accountType === 'E') {
            this.appMessageService.showAppMessage("Some fields are required.", "info");
            return;
        }
        const effDate = new Date(this.coverageInfo.controls["effDate"].value);

        if (effDate && this.coverageInfo.get('effDate')?.errors) {
            this.appMessageService.showAppMessage(`Unable to save. Effective Date must be within ${this.effDateRangeDays} days starting from ${this.sameDayTag ? 'today' : 'the next day'}.`, "error");
        } else if (this.saveChecker()) {
            this.appMessageService.showAppMessage("Your recent changes require re-calculation of amounts. Please click Show Computation button.", "info");
        } else if (this.tableForm.dirty && this.summarizedPremTag === 'N') {
            this.compute({ perils: this.dataSource.data, thenSave: true });
        } else if (this.tableForm.dirty && this.summarizedPremTag === 'Y') {
            this.compute({ thenSave: true });
        } else {
            // ? set policy header details to variable (can be saved but doesnt need to be on computation)
            let otherPolicyHeader = {
                ...this.policyHeaderDetailsForm.value
            };
            this.jsonDataService.contorlLoading(true);
            if (this.lineCd === "MC") {
                this.apiCallService.saveCoverage({
                    quoteId: this.productService.productInfo.quoteId,
                    tempId: this.coverageInfo.getRawValue().tempId,
                    lineCd: this.lineCd,
                    sublineCd: this.productService.productInfo.sublineCd,
                    planCd: this.productService.productInfo.planInfo.planCd
                }).subscribe((response: any) => {
                    //this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.showComputationIsClicked = false;
                            if (this.productService.productInfo.quoteId && (['A', 'E', 'B'].includes(this.userDetails.accountType))) {
                                this.apiCallService.updateAcceptance({
                                    quoteId: this.productService.productInfo.quoteId,
                                    // ? main header
                                    agentCd: this.policyHeader.agentDetail.value,
                                    bmaCd: this.coverageInfo.get('bmaDetail')?.value?.value || this.policyHeader.bmaDetail.value,
                                    clientCd: this.jsonDataService.data.client.find((a: any) => a.bmCode === this.clientTypeBmCode)?.clientCd || null, //this.policyHeader.clientTypeGroupDetail.value
                                    // ? below header (not required on computation)
                                    srcCd: otherPolicyHeader.sourceCodeDetail.value,
                                    srcExtCd: otherPolicyHeader.sourceExtensionDetail.value,
                                    referrorCd: otherPolicyHeader.referrorCodeDetail.value,
                                    projectCd: otherPolicyHeader.projectCodeDetail.value,
                                }).subscribe((response: any) => {
                                    this.jsonDataService.contorlLoading(false);
                                    this.securityService.checkRequestKeyResponse(response, () => {
                                        if (response.status === "SUCCESS") {
                                            //this.productInfo.policyHeaderDetails = form;
                                            //this.productService.setProductInfo2(this.productInfo);
                                            this.coverageInfo.patchValue({
                                                clientTypeGroupDetail: this.clientTypeBmCode
                                            });
                                            this.tableForm.markAsPristine();
                                            this.coverageInfo.markAsPristine();
                                            this.policyHeaderDetailsForm.markAsPristine();
                                            this.moveToStorage();
                                            this.appMessageService.showAppMessage("Successfully saved.", "success");
                                            //this.appMessageService.showAppMessage("Your policy header details has been saved successfully.", "success");
                                        } else {
                                            this.appMessageService.showAppMessage("Unable to save policy header details this time. Please contact BPI MS for more information.", "error");
                                        }
                                    });
                                });
                            } else {
                                this.jsonDataService.contorlLoading(false);
                                this.tableForm.markAsPristine();
                                this.coverageInfo.markAsPristine();
                                this.policyHeaderDetailsForm.markAsPristine();
                                this.moveToStorage();
                                this.appMessageService.showAppMessage("Successfully saved.", "success");
                            }
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage("Error saving your coverage.", "error");
                        }
                    });
                });
            }

            if (["PA", "CI"].includes(this.lineCd)) {
                this.apiCallService.saveCoverage({
                    quoteId: this.productService.productInfo.quoteId,
                    tempId: this.coverageInfo.getRawValue().tempId,
                    lineCd: this.productService.productInfo.lineCd,
                    sublineCd: this.productService.productInfo.sublineCd,
                    planCd: this.productService.productInfo.planInfo.planCd,
                    promoCode: this.coverageInfo.get('promoCode')?.value,
                    userId: this.userDetails.userId,
                    policyHeaderInfo: {
                        // ? main header
                        agentDetail: this.policyHeader.agentDetail,
                        bmaDetail: this.coverageInfo.get('bmaDetail')?.value || this.policyHeader.bmaDetail,
                        clientTypeGroupDetail: { value: this.jsonDataService.data.client.find((a: any) => a.bmCode === this.clientTypeBmCode)?.clientCd || null },
                        // ? below header (not required on computation)
                        sourceCodeDetail: otherPolicyHeader.sourceCodeDetail,
                        sourceExtensionDetail: otherPolicyHeader.sourceExtensionDetail,
                        referrorCodeDetail: otherPolicyHeader.referrorCodeDetail,
                        projectCodeDetail: otherPolicyHeader.projectCodeDetail,
                    }
                }).subscribe((response: any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.showComputationIsClicked = false;
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            sessionStorage.setItem("rid", res.recordId);
                            this.policyHeaderDetailsForm.patchValue({
                                agentDetail: res.headerDetails.agentDetail,
                                sourceCodeDetail: res.headerDetails.sourceCodeDetail,
                                sourceExtensionDetail: res.headerDetails.sourceExtensionDetail,
                                bmaDetail: res.headerDetails.bmaDetail,
                                referrorCodeDetail: res.headerDetails.referrorCodeDetail,
                                projectCodeDetail: res.headerDetails.projectCodeDetail,
                                clientTypeGroupDetail: this.jsonDataService.data.client.find((a: any) => a.clientCd === res.headerDetails?.clientTypeGroupDetail?.value)?.bmCode,
                            });
                            this.coverageInfo.patchValue({
                                agentDetail: res.headerDetails.agentDetail,
                                bmaDetail: res.headerDetails.bmaDetail,
                                clientTypeGroupDetail: this.jsonDataService.data.client.find((a: any) => a.clientCd === res.headerDetails?.clientTypeGroupDetail?.value)?.bmCode,
                            });
                            this.productService.productInfo.quoteId = res.quoteId;
                            this.tableForm.markAsPristine();
                            this.coverageInfo.markAsPristine();
                            this.policyHeaderDetailsForm.markAsPristine();
                            this.moveToStorage();
                            this.appMessageService.showAppMessage("Successfully saved.", "success");
                        } else {
                            this.appMessageService.showAppMessage("Error saving your coverage.", "error");
                        }
                    });
                });
            }

            if (this.lineCd === "HA") {
                this.apiCallService.saveCoverage({
                    quoteId: this.productService.productInfo.quoteId,
                    tempId: this.coverageInfo.getRawValue().tempId,
                    lineCd: this.lineCd,
                    sublineCd: this.productService.productInfo.sublineCd,
                    planCd: this.productService.productInfo.planInfo.planCd,
                }).subscribe((response: any) => {
                    //this.jsonDataService.contorlLoading(false);
                    this.securityService.checkRequestKeyResponse(response, () => {
                        if (response.status === "SUCCESS") {
                            this.showComputationIsClicked = false;
                            if (this.productService.productInfo.quoteId && (['A', 'E', 'B'].includes(this.userDetails.accountType))) {
                                this.apiCallService.updateAcceptance({
                                    quoteId: this.productService.productInfo.quoteId,
                                    // ? main header
                                    agentCd: this.policyHeader.agentDetail.value,
                                    bmaCd: this.coverageInfo.get('bmaDetail')?.value?.value ||  this.policyHeader.bmaDetail.value,
                                    clientCd: this.jsonDataService.data.client.find((a: any) => a.bmCode === this.clientTypeBmCode)?.clientCd || null, //this.policyHeader.clientTypeGroupDetail.value
                                    // ? below header (not required on computation)
                                    srcCd: otherPolicyHeader.sourceCodeDetail.value,
                                    srcExtCd: otherPolicyHeader.sourceExtensionDetail.value,
                                    referrorCd: otherPolicyHeader.referrorCodeDetail.value,
                                    projectCd: otherPolicyHeader.projectCodeDetail.value,
                                }).subscribe((response: any) => {
                                    this.jsonDataService.contorlLoading(false);
                                    this.securityService.checkRequestKeyResponse(response, () => {
                                        if (response.status === "SUCCESS") {
                                            //this.productInfo.policyHeaderDetails = form;
                                            //this.productService.setProductInfo2(this.productInfo);
                                            this.coverageInfo.patchValue({
                                                clientTypeGroupDetail: this.clientTypeBmCode
                                            });
                                            this.tableForm.markAsPristine();
                                            this.coverageInfo.markAsPristine();
                                            this.policyHeaderDetailsForm.markAsPristine();
                                            this.moveToStorage();
                                            this.appMessageService.showAppMessage("Successfully saved.", "success");
                                            //this.appMessageService.showAppMessage("Your policy header details has been saved successfully.", "success");
                                        } else {
                                            this.appMessageService.showAppMessage("Unable to save policy header details this time. Please contact BPI MS for more information.", "error");
                                        }
                                    });
                                });
                            } else {
                                this.jsonDataService.contorlLoading(false);
                                this.tableForm.markAsPristine();
                                this.coverageInfo.markAsPristine();
                                this.policyHeaderDetailsForm.markAsPristine();
                                this.moveToStorage();
                                this.appMessageService.showAppMessage("Successfully saved.", "success");
                            }
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage("Error saving your coverage.", "error");
                        }
                    });
                });
            }
        }
    }

    public displayItemText(itemCd: number): string {
        return this.jsonDataService.data.fireItemCd.find((a:any)=>a.itemCd == itemCd)?.itemLabel;
    }

    toggleEdit(fromScreen?:any): void {

        if(fromScreen && this.computeDependencies.some(e=>this.coverageInfo.get(e)?.dirty)){
            this.appMessageService.showAppMessage("Your recent changes require re-calculation of amounts. Please click Show Computation button.", "info");
            throw new Error();
        }

        this.editFlag = !this.editFlag;
        if (this.editFlag) {
            for (let peril of this.dataSource.data) {
                console.log({peril});
                if (!peril.perilCd) {
                    continue;

                }
                let row: FormGroup = this.formBuilder.group({
                    perilCd: [peril.perilCd, []],
                    premRt: [this.addDecimalPlaces(peril.premRt), { updateOn: 'blur' }],
                    tsiAmt: [this.addDecimalPlaces(peril.tsiAmt, 2), { updateOn: 'blur' }],
                    premAmt: [this.addDecimalPlaces(peril.premAmt, 2), { updateOn: 'blur' }],
                    commRt: [this.addDecimalPlaces(peril.commRt, 2), { updateOn: 'blur' }],
                    serviceFeeRt: [this.addDecimalPlaces(peril.serviceFeeRt, 2), { updateOn: 'blur' }],
                });

                row.get('premRt')?.valueChanges.subscribe(e => {
                    if (this.userDetails.accountType == 'A' && this.userDetails.premTag == 'N') {
                        peril.commRt = peril.rtOpts.filter((a: any) => a.premRt == e)[0]?.commRt;
                        peril.serviceFeeRt = peril.rtOpts.filter((a: any) => a.premRt == e)[0]?.serviceFeeRt;
                        this.onPremChange(e, peril);
                        console.log("peril:", peril.serviceFeeRt);
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                    } else if (["B", "E"].indexOf(this.userDetails.accountType) !== -1 && this.userDetails.premTag == 'N') {
                        peril.serviceFeeRt = peril.rtOpts.filter((a: any) => a.premRt == e)[0]?.serviceFeeRt;
                        this.onPremChange(e, peril);
                        console.log("peril:", peril.serviceFeeRt);
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                    } else {
                        let premRt = parseFloat(e || 0);

                        if (premRt > 100 || premRt < 0) {
                            // this.jsonDataService.contorlMsgBox('error', 'The value of the new rate is not within the allowable limit of the coverage.', 'Error');
                            this.appMessageService.showAppMessage('The value of the new rate is not within the allowable limit of the coverage.','error');
                            row.get('premRt')?.setValue(this.addDecimalPlaces(peril.premRt), { emitEvent: false });
                            return;
                        }
                        this.onPremChange(e, peril);
                        
                        row.get('premRt')?.setValue(this.addDecimalPlaces(e), { emitEvent: false });
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                        row.get('commRt')?.setValue(this.addDecimalPlaces(peril.commRt, 2), { emitEvent: false });
                        row.get('serviceFeeRt')?.setValue(this.addDecimalPlaces(peril.serviceFeeRt, 2), { emitEvent: false });
                    }
                    this.copyToAlliedPeril(peril);
                
                    const currentIndex = this.dataSource.data.findIndex(p => p.perilName === peril.perilName);
                    console.log(`Current Index: ${currentIndex}`);
                    const nextPeril = this.dataSource.data.find((p, index) => index > currentIndex);
                    console.log("Next Peril Object:", nextPeril);

                    if (!nextPeril) {
                        console.log("Next peril is undefined.");
                        return;
                    }

                    if (['Own Damage', 'Theft'].includes(peril.perilName)) {
                        console.log(`Retriggering serviceFeeRt change for next peril: ${nextPeril.perilName}`);
                        const nextRow = this.rows.at(this.dataSource.data.indexOf(nextPeril)) as FormGroup;
                        if (!nextRow) {
                            console.log("Next row form group is undefined.");
                            return;
                        }
                        this.onPremChange(e, nextPeril);
                        nextRow.get('serviceFeeRt')?.setValue(this.addDecimalPlaces(nextPeril.serviceFeeRt, 2), { emitEvent: false });
                        if (peril.perilName === 'Own Damage') {
                            const theftRow = (this.rows.controls as FormGroup[]).find(row => row.get('perilName')?.value === 'Theft');
                            if (theftRow) {
                                console.log("Updating Theft peril serviceFeeRt as well.");
                                this.onPremChange(e, theftRow.value);
                                theftRow.get('serviceFeeRt')?.setValue(this.addDecimalPlaces(theftRow.value.serviceFeeRt, 2), { emitEvent: false });
                            } else {
                                console.log("No Theft peril found in the list.");
                            }
                        }
                    }

                    
                })
                row.get('tsiAmt')?.valueChanges.subscribe(e => {
                    let tsiAmt = parseFloat(e || 0);
                    if (peril.minTsi && peril.maxTsi && (peril.minTsi > tsiAmt || peril.maxTsi < tsiAmt)) {
                        // this.jsonDataService.contorlMsgBox('error', 'The value of the new amount covered is not within the allowable limit of the coverage.', 'Error');
                        this.appMessageService.showAppMessage('The value of the new amount covered is not within the allowable limit of the coverage.','error');
                        row.get('tsiAmt')?.setValue(this.addDecimalPlaces(peril.tsiAmt, 2), { emitEvent: false });
                        return;
                    }
                    peril.tsiAmt = parseFloat(e || 0);
                    if (peril.fmvSw == 'Y' && peril.defaultSw != 'Y') {
                        this.getPerilPrem(peril);
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                    } else {
                        peril.premAmt = peril.tsiOpts.filter((a: any) => a.tsiAmt == e)[0]?.premAmt;
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                        this.getPerilPremAnt(peril);
                        row.get('premRt')?.setValue(this.addDecimalPlaces(peril.premRt), { emitEvent: false });
                    }
                    this.copyToAlliedPeril(peril);
                    this.copyIfVtpl(peril, tsiAmt);
                });
                row.get('premAmt')?.valueChanges.subscribe(e => {
                    let premAmt = parseFloat(e || 0);
                    let premRt = ((premAmt / peril.multiplierRate) / peril.tsiAmt) * 100;

                    if (premRt > 100 || premRt < 0) {
                        // this.jsonDataService.contorlMsgBox('error', 'The value of the new rate is not within the allowable limit of the coverage.', 'Error');
                        this.appMessageService.showAppMessage('The value of the new rate is not within the allowable limit of the coverage.','error');
                        row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                        return;
                    }

                    // if (this.userDetails.accountType == 'E' && (peril.minPremRtE > premRt || (peril.maxPremRtE && peril.maxPremRtE < premRt))) {
                    //     this.jsonDataService.contorlMsgBox('error', 'The value of the new rate is not within the allowable limit of the coverage.', 'Error');
                    //     row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                    //     return;
                    // }

                    if (peril.minPremRt > premRt || (peril.maxPremRt && peril.maxPremRt < premRt)) {
                        if (this.userDetails.accountType == 'E' && false) {
                            if (peril.minPremRt > premRt) {
                                this.jsonDataService.contorlMsgBox('notice', 'The entered premium rate is lower than the standard minimum rate.', 'Notice');
                            } else {
                                this.jsonDataService.contorlMsgBox('notice', 'The entered premium rate is higher than the standard maximum rate.', 'Notice');
                            }
                        } else {
                            // this.jsonDataService.contorlMsgBox('error', 'The value of the new rate is not within the allowable limit of the coverage.', 'Error');
                            this.appMessageService.showAppMessage('The value of the new rate is not within the allowable limit of the coverage.','error');
                            row.get('premAmt')?.setValue(this.addDecimalPlaces(peril.premAmt, 2), { emitEvent: false });
                            return;
                        }
                    }
                    peril.premAmt = premAmt;
                    this.getPerilPremAnt(peril);
                    this.getCommRt(peril);
                    this.getServiceFeeRt(peril);
                    this.getCommAmt(peril);
                    this.getServiceFeeAmt(peril);
                    this.getTotalPrem();
                    row.get('premRt')?.setValue(this.addDecimalPlaces(peril.premRt), { emitEvent: false });
                    row.get('commRt')?.setValue(this.addDecimalPlaces(peril.commRt, 2), { emitEvent: false });
                    row.get('serviceFeeRt')?.setValue(this.addDecimalPlaces(peril.serviceFeeRt, 2 ), { emitEvent: false });
                    this.copyToAlliedPeril(peril);
                });

                row.get('commRt')?.valueChanges.subscribe(e => {
                    let commRt = parseFloat(e || 0);

                    // VALIDATE?
                    if ((peril.maxCommRt && commRt > peril.maxCommRt) || commRt < 0) {
                        // this.jsonDataService.contorlMsgBox('error', 'The value of the new comm rate is not within the allowable limit of the coverage.', 'Error');
                        this.appMessageService.showAppMessage('The value of the new comm rate is not within the allowable limit of the coverage.','error');
                        row.get('commRt')?.setValue(this.addDecimalPlaces(peril.commRt, 2), { emitEvent: false });
                        return;
                    } else {
                        peril.commRt = commRt;
                    }
                    this.getCommAmt(peril);
                    this.copyToAlliedPeril(peril);
                    this.getTotalPrem();
                });

                row.get('serviceFeeRt')?.valueChanges.subscribe(e => {
                    let serviceFeeRt = parseFloat(e || 0);

                    // VALIDATE?
                    if ((peril.maxServiceFeeRt && serviceFeeRt > peril.maxServiceFeeRt) || serviceFeeRt < 0) {
                        // this.jsonDataService.contorlMsgBox('error', 'The value of the new comm rate is not within the allowable limit of the coverage.', 'Error');
                        this.appMessageService.showAppMessage('The value of the new comm rate is not within the allowable limit of the coverage.','error');
                        row.get('serviceFeeRt')?.setValue(this.addDecimalPlaces(peril.serviceFeeRt, 2), { emitEvent: false });
                        return;
                    } else {
                        peril.serviceFeeRt = serviceFeeRt;
                    }
                    this.getServiceFeeAmt(peril);
                    this.copyToAlliedPeril(peril);
                    this.getTotalPrem();
                });
                this.rows.push(row);
            }

        } else {
            this.rows.clear();
        }

    }

    copyToAlliedPeril(peril: any): void {
        if (peril.groupCd ) {
            if(this.lineCd == 'HA'){
                this.dataSource.data.filter(p => p.groupCd === peril.groupCd).forEach((e: any) => {
                    e.premRt = peril.premRt;
                    e.commRt = peril.commRt;
                    this.getPerilPrem(e);
                });
            }else {
                this.dataSource.data.filter(p => p.groupCd === peril.groupCd && p.fixedPremTag == 'Y').forEach((e: any) => {
                    e.tsiAmt = peril.tsiAmt;
                    e.premRt = peril.premRt;
                    e.commRt = peril.commRt;
                    this.getPerilPrem(e);
                });
            }
        }

        this.dataSource.data.filter(p => p.basicPerilCd === peril.perilCd && peril.fmvSw == 'Y' && peril.defaultSw != 'Y').forEach((e: any) => {
            e.tsiAmt = peril.tsiAmt;
            this.getPerilPrem(e);
        });
    }

    copyIfVtpl(peril: any, tsiAmt: number) {
        if (this.lineCd == 'MC' && peril.perilCd == '3' && !this.editablePD) {
            let pdControl = this.rows.controls.filter(rowControl => rowControl.value.perilCd == '4')[0] as FormGroup;
            pdControl && pdControl.controls.tsiAmt.setValue(peril.tsiAmt);
        }
    }

    getCommAmt(peril: any): void {
        peril.commAmt = (peril.premAmt * peril.commRt / 100) || 0;
    }

    getServiceFeeAmt(peril: any): void {
        peril.serviceFeeAmt = (peril.premAmt * peril.serviceFeeRt / 100) || 0;
        console.log(`Peril: ${peril.perilCd}, Service Fee Amount: ${peril.serviceFeeAmt}`);
    }
    

    getCommRt(peril: any): void {
        console.log("Calculating Commission Rate for:", peril);
        if (this.userDetails.accountType == 'A' || this.coverageInfo.get('promoUserType')?.value == 'A') {
            if (peril.rtOpts.filter((a: any) => a.premRt == peril.premRt).length > 0) {
                peril.commRt = peril.rtOpts.filter((a: any) => a.premRt == peril.premRt)[0]?.commRt;
                return;
            }
            let commRt = ((1 - (peril.rp / peril.premRt)) * 100) || 0;
            if (peril.maxCommRt && commRt > peril.maxCommRt) {
                peril.commRt = peril.maxCommRt;
            } else if (commRt < peril.minCommRt) {
                peril.commRt = peril.minCommRt;
            } else {
                peril.commRt = commRt;
            }
        } else {
            peril.commRt = 0;
        }

        console.log("Final Commission Rate:", peril.commRt);
    }


    getServiceFeeRt(peril: any): void {
        console.log("Calculating Service Fee Rate for:", peril);
        if ((["A", "E", "B"].indexOf(this.userDetails.accountType) !== -1) || this.coverageInfo.get('promoUserType')?.value == 'A') {
            if (peril.rtOpts.filter((a: any) => a.premRt == peril.premRt).length > 0) {
                peril.serviceFeeRt = peril.rtOpts.filter((a: any) => a.premRt == peril.premRt)[0]?.serviceFeeRt;
                return;
            }
            let serviceFeeRt = ((1 - (peril.rp / peril.premRt)) * 100) || 0;

            if (peril.maxServiceFeeRt && serviceFeeRt > peril.maxServiceFeeRt) {
                peril.serviceFeeRt = peril.maxServiceFeeRt;
            } else if (serviceFeeRt < peril.minServiceFeeRt) {
                peril.serviceFeeRt = peril.minServiceFeeRt;
            } else {
                peril.serviceFeeRt = serviceFeeRt;
            }
        } else {
            peril.serviceFeeRt = 0;
        }
    
        console.log("Final Service Fee Rate:", peril.serviceFeeRt);
    }
    

    getPerilPrem(peril: any): void {
        peril.premAmt = peril.tsiAmt * (peril.premRt / 100) * peril.multiplierRate;
        this.getCommAmt(peril);
        this.getServiceFeeAmt(peril);
        this.getTotalPrem();
    }

    getPerilPremAnt(peril: any): void {
        peril.premRt = ((peril.premAmt / peril.multiplierRate) / peril.tsiAmt) * 100;
        this.getCommAmt(peril);
        this.getServiceFeeAmt(peril);
        this.getTotalPrem();
    }

    getTotalPrem(): void {
        this.dataSource.data[this.dataSource.data.length - 1].premAmt = this.dataSource.data.filter(a => a.perilCd).map(a => a.premAmt).reduce((a = 0, e) => a + e);
        this.dataSource.data[this.dataSource.data.length - 1].commAmt = this.dataSource.data.filter(a => a.perilCd).map(a => a.commAmt).reduce((a = 0, e) => a + e);
        this.dataSource.data[this.dataSource.data.length - 1].serviceFeeAmt = this.dataSource.data.filter(a => a.perilCd).map(a => a.serviceFeeAmt).reduce((a = 0, e) => a + e);
        console.log("Total Service Fee Amount:", this.dataSource.data[length].serviceFeeAmt);
    }

    onPremChange(event: any, row: any): void {
        row.premRt = parseFloat(event || 0);
        this.getCommRt(row);
        this.getServiceFeeRt(row);
        this.getPerilPrem(row);
    }

    addDecimalPlaces(val: string | Number, length?: number): String | null {
        let num = parseFloat(val + "");
        if (isNaN(num)) {
            return '0.'.padEnd(length || 6, '0');
        }
        num = Math.round((num + Number.EPSILON) * Math.pow(10, (length || 6))) / Math.pow(10, (length || 6));
        let parts: String[] = String(num).split('.');
        parts[1] = String(parts[1] || '').padEnd(length || 6, '0');
        return parts.join('.');
    }

    compareByValue(f1: any, f2: any) {
        return f1 && f2 && f1.name === f2.name;
    }

    mergePerilfromMtn = {
        MC: (perils: any) => {

            let conType = this.jsonDataService.data.engineSeries.filter((a: any) => a.carCompanyCd == this.productService.productInfo.vehicleInfo.makeDetail.value && a.makeCd == this.productService.productInfo.vehicleInfo.modelDetail.value && a.seriesCd == this.productService.productInfo.vehicleInfo.subModelDetail.value)[0]?.contractTypeCd || 1;
            let carCompGrp = this.jsonDataService.data.carCompanies.filter((a: any) => a.carCompanyCd == this.productService.productInfo.vehicleInfo.makeDetail.value)[0]?.carCompGrp;

            // promoUserType
            // promoAgentGrp
            // promoIssCd

            let issCd = this.coverageInfo.get('promoIssCd')?.value || this.userDetails.issCd;
            let agentGrp = this.coverageInfo.get('promoAgentGrp')?.value || this.userDetails.agentGrp;
            let {clientCd, uppaText} = this.jsonDataService.data.client.find((a: any) => a.bmCode === this.clientTypeBmCode) || {};
            let defClientCd = this.m093.clientCd;

            let makeTypeCd = this.jsonDataService.data.engineSeries.filter((a: any) => a.carCompanyCd == this.productService.productInfo.vehicleInfo.makeDetail.value && a.makeCd == this.productService.productInfo.vehicleInfo.modelDetail.value && a.seriesCd == this.productService.productInfo.vehicleInfo.subModelDetail.value)[0]?.makeTypeCd[0];
            let carTypeCd = this.jsonDataService.data.carType.filter((e: any) => {
                return e.filterMakeTypeCd.split(',').indexOf(makeTypeCd) != -1;
            })[0].carTypeCd;

            let uppaPerilCd = this.parameterService.paramN('UPPA');

            if (this.userDetails.accountType == 'E') {
                agentGrp = this.jsonDataService.data.agent.filter((b: any) => b.agentCd === this.coverageInfo.get('agentDetail')?.value?.value)[0]?.agentGrp //this.coverageInfo.get('agentDetail')?.value.value
            }

            if(clientCd != defClientCd){
                this.m150 = this.jsonDataService.data.BMM150.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd &&
                    a.clientCd == clientCd &&
                    a.carCompGrp == carCompGrp);
                this.m113 = this.jsonDataService.data.BMM113.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd &&
                    a.clientCd == clientCd);
                
                this.m070 = [];
                this.m071 = [];
                this.m073 = [];
                this.m095 = [];
                this.m096 = [];
                this.m097 = [];
                this.m098 = [];
                this.m099 = [];
                this.m100 = [];
                
            } else if (this.userDetails.accountType == 'A' || this.coverageInfo.get('promoUserType')?.value == 'A') {
                this.m070 = this.jsonDataService.data.BMM070.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd);
                this.m071 = this.jsonDataService.data.BMM071.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.carCompGrp == carCompGrp &&
                    a.issCd == issCd);
                this.m073 = this.jsonDataService.data.BMM073.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.agentGrp == agentGrp);

                if (conType != 1 && this.m113.length == 0 && this.m070.length == 0 && this.m071.length == 0 && this.m073.length == 0 && this.m095.length == 0 && this.m096.length == 0 && this.m097.length == 0 && this.m098.length == 0 && this.m099.length == 0 && this.m100.length == 0) {

                    this.m070 = this.jsonDataService.data.BMM070.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.issCd == issCd);
                    this.m071 = this.jsonDataService.data.BMM071.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.carCompGrp == carCompGrp &&
                        a.issCd == issCd);
                    this.m073 = this.jsonDataService.data.BMM073.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.agentGrp == agentGrp);
                    this.m095 = this.jsonDataService.data.BMM095.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.carCompGrp == carCompGrp &&
                        a.issCd == issCd);   
                    this.m096 = this.jsonDataService.data.BMM096.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.issCd == issCd);  
                    this.m097 = this.jsonDataService.data.BMM097.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.issCd == issCd);   
                    this.m098 = this.jsonDataService.data.BMM098.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.issCd == issCd);   
                    this.m099 = this.jsonDataService.data.BMM099.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.issCd == issCd); 
                    this.m100 = this.jsonDataService.data.BMM100.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                        1 == a.contractTypeCd &&
                        a.carCompGrp == carCompGrp &&
                        a.issCd == issCd);      
                }
                this.m113 = [];
                this.m150 = [];
            } else if (["B", "E"].indexOf(this.userDetails.accountType) !== -1 || this.coverageInfo.get('promoUserType')?.value == 'A') {
                this.m095 = this.jsonDataService.data.BMM095.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd);
                this.m096 = this.jsonDataService.data.BMM096.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.carCompGrp == carCompGrp &&
                    a.issCd == issCd);
                this.m097 = this.jsonDataService.data.BMM097.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd);
                this.m098 = this.jsonDataService.data.BMM098.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.carCompGrp == carCompGrp &&
                    a.issCd == issCd);
                this.m099 = this.jsonDataService.data.BMM099.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.issCd == issCd);
                this.m100 = this.jsonDataService.data.BMM100.filter((a: any) => a.lineCd == this.productService.productInfo.lineCd &&
                    conType == a.contractTypeCd &&
                    a.carCompGrp == carCompGrp &&
                    a.issCd == issCd);
            } else {
                this.m070 = [];
                this.m071 = [];
                this.m073 = [];
                this.m113 = [];
                this.m150 = [];
                this.m095 = [];
                this.m096 = [];
                this.m097 = [];
                this.m098 = [];
                this.m099 = [];
                this.m100 = [];
            }

            for (let peril of perils) {
                console.log("Defining Rate Options...");
                peril.rtOpts = [];

                if (peril.rtOpts.length == 0 && carCompGrp) {
                    peril.rtOpts = this.m150.filter((a: any) => a.perilCd == peril.perilCd);
                    console.log("Using BMM150.");
                }

                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = this.m113.filter((a: any) => a.perilCd == peril.perilCd);
                    console.log("Using BMM113.");
                }

                if ( ["A"].indexOf(this.userDetails.accountType) !== -1 ) {
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m073.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM073.");
                    }
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m071.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM071.");
                    }
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m070.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM070.");
                    }
                } else if ( ["E"].indexOf(this.userDetails.accountType) !== -1 ) {
                    if (peril.rtOpts.length == 0  && carCompGrp) {
                        peril.rtOpts = this.m096.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM096.");
                    }
    
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m095.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM095.");
                    }
                } else if ( ["B"].indexOf(this.userDetails.accountType) !== -1 ) {
                    if (peril.rtOpts.length == 0  && carCompGrp) {
                        peril.rtOpts = this.m098.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM098.");
                    }
    
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m097.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM097.");
                    }
                } else if ( ["P"].indexOf(this.userDetails.accountType) !== -1 ) {
                    if (peril.rtOpts.length == 0  && carCompGrp) {
                        peril.rtOpts = this.m100.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM100.");
                    }
    
                    if (peril.rtOpts.length == 0) {
                        peril.rtOpts = this.m099.filter((a: any) => a.perilCd == peril.perilCd);
                        console.log("Using BMM099.");
                    }
                }

                if (peril.rtOpts.length !== 0) {
                    peril.maxPremRt = Math.max.apply(Math, peril.rtOpts.map((e: any) => e['premRt']));
                    peril.minPremRt = Math.min.apply(Math, peril.rtOpts.map((e: any) => e['premRt']));
                    peril.maxCommRt = Math.max.apply(Math, peril.rtOpts.map((e: any) => e['commRt']));
                    peril.minCommRt = Math.min.apply(Math, peril.rtOpts.map((e: any) => e['commRt']));
                    peril.maxServiceFeeRt = Math.max.apply(Math, peril.rtOpts.map((e: any) => e['serviceFeeRt']));
                    peril.minServiceFeeRt = Math.min.apply(Math, peril.rtOpts.map((e: any) => e['serviceFeeRt']));
                }

                console.log("Peril Rate Options");
                console.log(peril.rtOpts);
                console.log(peril.maxServiceFeeRt);
                console.log(peril.minServiceFeeRt);

                peril.fmvSw = this.planPerilMtn.filter((a: any) => a.perilCd == peril.perilCd)[0]?.fmvSw;

                let pMtn = this.perilMtn.filter((a: any) => a.perilCd == peril.perilCd)[0];

                peril.defaultSw = pMtn?.defaultSw;
                peril.perilType = pMtn?.perilType;
                peril.groupCd = pMtn?.groupCd;
                peril.shortDesc = pMtn?.shortDesc;
                peril.basicPerilCd = pMtn?.basicPerilCd;
                peril.multiplierRate = (pMtn?.multiplierRate) / 100;
                peril.fixedPremTag = pMtn?.fixedPremTag
                // ? Old : peril.fixedSiTag = pMtn?.fixedSiTag
                // * New : If USER_TYPE = E Or A, VTPL - Bodily Injury [perilCd = 3], dropdown should be enabled.
                if (peril.perilCd == 3) {
                    peril.fixedSiTag = (['A', 'E'].indexOf(this.userDetails.accountType) != -1 && peril.perilCd === 3) ? 'N' : pMtn?.fixedSiTag;
                } else if (peril.perilCd == 4 && this.editablePD) {
                    peril.fixedSiTag = (['A', 'E'].indexOf(this.userDetails.accountType) != -1 && peril.perilCd === 4) ? 'N' : pMtn?.fixedSiTag;
                }
                peril.perilName = pMtn?.perilLname;

                let def = peril.rtOpts.filter((a: any) => a.defaultTag == 'Y')[0];
                if (def) {
                    peril.defCommRt = def?.commRt;
                    peril.defPremRt = def?.premRt;
                } else {
                    peril.defCommRt = pMtn?.defaultCommRate;
                    peril.defPremRt = pMtn?.defaultRate;
                }
                peril.rp = peril.defPremRt * (1 - (peril.defCommRt / 100)) || 0;

                let limits = this.m094.filter((a: any) => a.perilCd == peril.perilCd);
                if(limits.length != 0){
                    limits = limits[0];
                }else{
                    limits = null;
                }
                if (limits) {
                    peril.maxTsi = limits?.siMaxPct && this.fmv.fmvValue + (this.fmv.fmvValue * (limits.siMaxPct / 100));
                    peril.minTsi = limits?.siMaxPct && this.fmv.fmvValue - (this.fmv.fmvValue * (limits.siMinPct / 100));
                    peril.maxTsi = peril.maxTsi > limits.siAmtLimit ? limits.siAmtLimit : peril.maxTsi;
                    peril.maxPremRtE = limits?.premRtMax || peril.maxPremRt;
                    peril.minPremRtE = limits?.premRtMin || peril.minPremRt;
                } else {
                    if (peril.fmvSw == 'Y' && peril.defaultSw != 'Y') {
                        peril.maxTsi = this.fmv.fmvValue;
                        peril.minTsi = this.fmv.fmvValue;
                        peril.maxPremRtE = peril.maxPremRt;
                        peril.minPremRtE = peril.minPremRt;
                    } else {
                        peril.maxTsi = null;
                        peril.minTsi = null;
                    }
                }

                peril.tsiOpts = [];
                if (peril.perilCd == uppaPerilCd){
                    peril.shortDesc = this.productService.productInfo.vehicleInfo.uppaText || uppaText;
                    continue;
                } 

                if (carCompGrp) {
                    peril.tsiOpts = this.jsonDataService.data.perilAllowedTsiperCarComp.filter((a: any) =>
                        a.perilCd == peril.perilCd &&
                        a.clientCd == clientCd &&
                        a.carTypeCd == carTypeCd &&
                        a.lineCd == this.lineCd &&
                        a.carCompGrp == carCompGrp);
                }
                if (peril.tsiOpts.length == 0) {
                    peril.tsiOpts = this.m037.filter((a: any) =>
                        a.perilCd == peril.perilCd &&
                        a.clientCd == clientCd &&
                        a.carTypeCd == carTypeCd &&
                        a.lineCd == this.lineCd);
                }

            }
        },
        HA: (perils: any) => {
            let clientCd = this.jsonDataService.data.client.find((a: any) => a.bmCode === this.clientTypeBmCode)?.clientCd;

            let zoneCd = this.zoneCd;
            let classCd = this.getClassCd();

            let catPerilEq:any[] = this.parameterService.paramV('CAT_PERIL_EQ')?.split(',') || [];
            let catPerilTy:any[] = this.parameterService.paramV('CAT_PERIL_TY')?.split(',') || [];
            let catPerilFl:any[] = this.parameterService.paramV('CAT_PERIL_FL')?.split(',') || [];

            let defClientCd = this.m093.clientCd;
            let today = new Date();

            let {sublineCd, planInfo:{planCd}, propertyInfo:{noOfFloor}} = this.productService.productInfo;

            let m169 = [];
            let m168 = [];

            let mtnWithFloor = [];
            let mtnWithoutFloor = [];

            if(clientCd != defClientCd){
                m169 = this.jsonDataService.data.BMM169.filter(
                    (a:any) => a.clientCd == clientCd 
                            && a.lineCd == this.lineCd
                            && a.classCd == classCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));

                m168 = this.jsonDataService.data.BMM168.filter(
                    (a:any) => a.clientCd == clientCd 
                            && a.lineCd == this.lineCd
                            && a.classCd == classCd
                            && a.zoneCd == zoneCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
            }
            

            if (this.userDetails.accountType == 'A' || this.coverageInfo.get('promoUserType')?.value == 'A') {
                mtnWithFloor = this.jsonDataService.data.BMM171.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
    
                mtnWithoutFloor = this.jsonDataService.data.BMM170.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && a.zoneCd == zoneCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
            }

            if (this.userDetails.accountType == 'B' || this.coverageInfo.get('promoUserType')?.value == 'B') {
                mtnWithFloor = this.jsonDataService.data.BMM173.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
    
                mtnWithoutFloor = this.jsonDataService.data.BMM172.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && a.zoneCd == zoneCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
            }

            if (this.userDetails.accountType == 'E' && mtnWithFloor.length == 0 && mtnWithoutFloor.length == 0) {
                mtnWithFloor = this.jsonDataService.data.BMM175.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
    
                mtnWithoutFloor = this.jsonDataService.data.BMM174.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && a.zoneCd == zoneCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
            }

            if (this.userDetails.accountType == 'P') {  
                mtnWithFloor = this.jsonDataService.data.BMM177.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
    
                mtnWithoutFloor = this.jsonDataService.data.BMM176.filter(
                    (a:any) => a.lineCd == this.lineCd
                            && a.sublineCd == sublineCd
                            && a.planCd == planCd
                            && a.classCd == classCd
                            && a.zoneCd == zoneCd
                            && today > new Date(a.effDateFrom)
                            && (!a.effDateTo || today < new Date(a.effDateTo)));
            }

            for (let peril of perils) {
                let catZoneCd:any = null;

                if(catPerilEq.includes(peril.perilCd+'')){
                    catZoneCd = this.eqZoneCd;
                }else if(catPerilFl.includes(peril.perilCd+'')){
                    catZoneCd = this.flZoneCd;
                }else if(catPerilTy.includes(peril.perilCd+'')){
                    catZoneCd = this.tyZoneCd;
                }
                

                peril.rtOpts = [];

                peril.rtOpts = m169.filter((a:any)=> a.perilCd == peril.perilCd && a.noOfFloor == noOfFloor);
                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = m169.filter((a:any)=> a.perilCd == peril.perilCd && a.noOfFloor == 999 && a.catZoneCd == catZoneCd);
                }

                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = m168.filter((a:any)=> a.perilCd == peril.perilCd);
                }

                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = mtnWithFloor.filter((a:any)=> a.perilCd == peril.perilCd && a.noOfFloor == noOfFloor && a.catZoneCd == catZoneCd);
                }

                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = mtnWithFloor.filter((a:any)=> a.perilCd == peril.perilCd && a.noOfFloor == 999 && a.catZoneCd == catZoneCd);
                }

                if (peril.rtOpts.length == 0) {
                    peril.rtOpts = mtnWithoutFloor.filter((a:any)=> a.perilCd == peril.perilCd);
                }
                


                if (peril.rtOpts.length !== 0) {
                    peril.maxPremRt = Math.max.apply(Math, peril.rtOpts.map((e: any) => e['premRt']));
                    peril.minPremRt = Math.min.apply(Math, peril.rtOpts.map((e: any) => e['premRt']));
                    peril.maxCommRt = Math.max.apply(Math, peril.rtOpts.map((e: any) => e['commRt']));
                    peril.minCommRt = Math.min.apply(Math, peril.rtOpts.map((e: any) => e['commRt']));
                }


                let pMtn = this.perilMtn.filter((a: any) => a.perilCd == peril.perilCd)[0];
                peril.fmvSw = this.planPerilMtn.filter((a: any) => a.perilCd == peril.perilCd)[0]?.fmvSw;
                peril.defaultSw = pMtn?.defaultSw;
                peril.perilType = pMtn?.perilType;
                peril.groupCd = pMtn?.groupCd;
                peril.shortDesc = pMtn?.shortDesc;
                peril.basicPerilCd = pMtn?.basicPerilCd;
                peril.multiplierRate = (pMtn?.multiplierRate) / 100;
                peril.fixedPremTag = pMtn?.fixedPremTag
                peril.perilName = pMtn?.perilLname;
                peril.fixedSiTag = pMtn?.fixedSiTag;

                let def = peril.rtOpts.filter((a: any) => a.defaultTag == 'Y')[0];
                if (def) {
                    peril.defCommRt = def?.commRt;
                    peril.defPremRt = def?.premRt;
                } else {
                    peril.defCommRt = pMtn?.defaultCommRate;
                    peril.defPremRt = pMtn?.defaultRate;
                }
                peril.rp = peril.defPremRt * (1 - (peril.defCommRt / 100)) || 0;

            }
        }
    }

    setPremiumCharges(): void {
        if (this.lineCd === "MC") {
            const yearArr = this.ara.map((b: any) => b.year);

            this.premiumCharges.premiumAmt = this.ara.map((b: any) => b.premAmt).reduce((b: any, c: any) => b + c);
            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt;

            // NET PREMIUMS - [START]
            this.premiumCharges.totalPeril = 0;
            this.premiumCharges.perils = [];
            for (let peril of this.jsonDataService.data.peril.filter((a: any) => a.lineCd === this.productService.productInfo.lineCd).filter((a: any) =>
                this.peril.map((b: any) => b.perilCd).indexOf(a.perilCd) !== -1
            )) {
                let total = 0;
                for (let key of yearArr) {
                    total += this.peril.filter((a: any) => a.year === key && a.perilCd === peril.perilCd)[0].premAmt;
                }

                this.premiumCharges.perils.push({
                    name: peril.perilLname,
                    value: total
                });

                this.premiumCharges.totalPeril += total;
            }
            // NET PREMIUMS - [END]

            // TAXES - [START]
            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];
            for (let tax of this.jsonDataService.data.tax.filter((a: any) =>
                this.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1
            )) {
                let total = 0;
                for (let key of yearArr) {
                    total += this.taxes.filter((a: any) => a.year === key && a.taxCd === tax.taxCd)[0].taxAmt;
                }

                this.premiumCharges.amountDue += total;
                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: total
                    });
                } else {
                    otherCharges += total;
                }

                this.premiumCharges.totalTax += total;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }
            // TAXES - [END]

            this.premiumCharges.deductibleAmt = this.ara[0]?.dedAmt;
            this.premiumCharges.towingAmt = this.ara[0]?.towingAmt;
            this.premiumCharges.repairLimitAmt = this.ara[0]?.repairLimitAmt;
            this.premiumCharges.commissionAmt = this.ara.map((b: any) => b.commAmt).reduce((b: any, c: any) => b + c);;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
        }

        if (["PA", "CI"].includes(this.lineCd)) {
            this.premiumCharges.premiumAmt = 0;
            this.premiumCharges.premiumAmt = this.perilInfo.premAmt;

            let otherCharges = 0;
            this.premiumCharges.totalTax = 0;
            this.premiumCharges.taxes = [];

            for (let tax of this.jsonDataService.data.tax.filter((a: any) => this.taxes.map((b: any) => b.taxCd).indexOf(a.taxCd) !== -1)) {
                let taxAmt = 0;

                taxAmt = this.taxes.filter((a: any) => a.taxCd === tax.taxCd)[0].taxAmt;

                if (tax.ocTag === "N") {
                    this.premiumCharges.taxes.push({
                        name: tax.taxName,
                        value: taxAmt
                    });
                } else {
                    otherCharges += taxAmt;
                }

                this.premiumCharges.totalTax += taxAmt;
            }

            if (otherCharges !== 0) {
                this.premiumCharges.taxes.push({
                    name: "Other Charges",
                    value: otherCharges
                });
            }

            this.premiumCharges.amountDue = this.premiumCharges.premiumAmt + this.premiumCharges.totalTax;
            this.premiumCharges.commissionAmt = this.perilInfo.comAmount;
            this.premiumCharges.netDue = this.premiumCharges.amountDue - this.premiumCharges.commissionAmt;
            this.coverages.pop();
        }
    }

    checkerAgentCdParam(): boolean {
        if (this.agentSelected == this.bpiAgentCd)
            return true;
        return false;
    }

    agentChanged(e: any) {
        if (e?.value && this.userDetails.accountType === 'E') {
            this.agentSelected = e.value;
        }
    }

    private clientTypeGrpChanged(e: any) {
        // ? this changes noOfDays (only if lineCd = CI) depends on mtn tables
        if (e?.value && this.lineCd === "CI") {
            // try temp test [not yet final]
            let noOfDays = this.jsonDataService.data.BMM134.filter((a: any) => {
                return a.clientCd === e.value
                    && a.lineCd === this.lineCd
                    && a.sublineCd == this.productService.productInfo.sublineCd
                    && a.planCd == this.productService.productInfo.planInfo.planCd
            })[0]?.noOfDays;
            // if not available in bmm134
            if (!noOfDays) {
                noOfDays = this.jsonDataService.data.BMM118.filter((a: any) => {
                    return a.userType === this.userDetails.accountType
                        && a.lineCd === this.lineCd
                        && a.sublineCd == this.productService.productInfo.sublineCd
                        && a.planCd == this.productService.productInfo.planInfo.planCd
                })[0]?.noOfDays;
            }
            if (noOfDays) {
                this.coverageInfo.patchValue({
                    noOfDays: noOfDays
                });
            }
        }
    }

    private getEffDateParams():void{
        let productInfo = this.productService.productInfo;
        let params: any = {
            plateNo: productInfo.vehicleInfo.plateNo,
            lineCd: productInfo.lineCd,
            sublineCd: productInfo.sublineCd,
            veTypeTag: productInfo.vehicleInfo.vehicleType,
            quoteId : productInfo.quoteId
        }
        if(this.userDetails.email && this.userDetails.accountType == 'P'){
            params.firstName = this.userDetails.name;
            params.customerName = this.userDetails.displayName;
            params.mobileNo = this.userDetails.mobileNo;
            params.emailAddress = this.userDetails.email;
            params.chassisNo = productInfo.vehicleInfo.chassisNo;
            params.engineNo = productInfo.vehicleInfo.engineNo;
            params.mvFileNo = productInfo.vehicleInfo.mvFileNo;
        }
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.getEffDateParams(params).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    this.productService.productInfo.reEffDateFlag = false;
                    if(res.errorMessage){
                        this.productService.setEffDateParams(new Date(res.effDate),false,true);
                        this.coverageInfo.get('effDate')?.disable();
                        this.disableShowCompBtn = true;
                        this.dialogBoxService.open({
                            messageType: "I",
                            content: res.errorMessage,
                            buttons: [ {
                                text: "Close"
                            }]
                        });
                        this.coverageInfo.patchValue({
                            effDate: new Date(res.effDate)
                        });
                    }else if(productInfo.vehicleInfo.plateNo && res.plateNo != productInfo.vehicleInfo.plateNo){
                        this.appMessageService.showAppMessage("Error getting effective date parameters.", "error");
                        this.productService.setEffDateParams(new Date(res.effDate),false,true);
                        this.coverageInfo.get('effDate')?.disable();
                        this.disableShowCompBtn = true;
                    } else if(res.effDate){
                        this.coverageInfo.patchValue({
                            effDate: new Date(res.effDate)
                        });
                        res.disableEffDate ? this.coverageInfo.get('effDate')?.disable() : this.coverageInfo.get('effDate')?.enable();
                        this.productService.setEffDateParams(new Date(res.effDate),true,res.disableEffDate);
                        this.maxEffDate = new Date(this.productService.productInfo.coverageInfo?.effDate);
                        this.disableShowCompBtn = false;
                    } else{
                        this.disableShowCompBtn = false;
                        res.disableEffDate ? this.coverageInfo.get('effDate')?.disable() : this.coverageInfo.get('effDate')?.enable();
                    }
                } else {
                    this.appMessageService.showAppMessage("Error getting effective date parameters.", "error");
                }
                this.continueOnInit();
            });
        });
    }

    //*******************************************************************************/
    //*                                                                             */
    //*                           AUTO-COMPLETE FUNCTIONS                           */
    //*                                                                             */
    //*******************************************************************************/

    //* AGENT AUTO-COMPLETE FUNCTIONS */
    getAgents(): any[] {
        let agentOptions = [];
        const defaultAgentCd093 = this.jsonDataService.data.userType.find((a: any) => a.userType === (this.userDetails.accountType || 'P'))?.agentCd;
        for (let agent of this.jsonDataService.data.agent) {
            if (agent?.issCd === this.userDetails?.issCd || agent?.agentCd === defaultAgentCd093) {
                agentOptions.push({
                    value: agent.agentCd,
                    text: (agent.bmCode ? agent.bmCode + " - " : "") + agent.agentName
                });
            }
        }
        agentOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return agentOptions;
    }
    setAgentViaInput(): void {
        for (const agent of this.getAgents()) {
            if (agent.text === this.coverageInfo.controls["agentDetail"].value) {
                this.coverageInfo.controls["agentDetail"].setValue(agent);
            }
        }
    }

    //* SOURCE CODE AUTO-COMPLETE FUNCTIONS */
    getSourceCodes(): any[] {
        let sourceCodeOptions = [];
        let filterSrcCds = [];

        if(this.agentType == 'D'){
            filterSrcCds = this.jsonDataService.data.srcCdPerAgent.filter((a:any)=>a.agentCd == this.userDetails.userId).map((a:any)=>a.srcCd);
            for (let sourceCode of this.jsonDataService.data.src) {
                if(filterSrcCds.indexOf(sourceCode.srcCd)>-1){
                    sourceCodeOptions.push({
                        value: sourceCode.srcCd,
                        text: sourceCode.srcCdDesc
                    });
                }
            }
        }else{
            for (let sourceCode of this.jsonDataService.data.src) {
                sourceCodeOptions.push({
                    value: sourceCode.srcCd,
                    text: sourceCode.srcCdDesc
                });
            }
        }

        
        sourceCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return sourceCodeOptions;
    }
    setSourceCodeViaInput(): void {
        for (const sourceCode of this.getSourceCodes()) {
            if (sourceCode.text === this.policyHeaderDetailsForm.controls["sourceCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["sourceCodeDetail"].setValue(sourceCode);
            }
        }
    }

    //* SOURCE EXTENSION AUTO-COMPLETE FUNCTIONS */
    getSourceExtensions(): any[] {
        let sourceExtensionOptions: any[] = [];
        let filterSrcExtCds = [];
        if(this.agentType == 'D'){
            filterSrcExtCds = this.jsonDataService.data.srcExtCdPerAgent.filter((a:any)=>a.agentCd == this.userDetails.userId).map((a:any)=>a.srcExtCd);
            for (let sourceExtension of this.jsonDataService.data.srcExt) {
                if(filterSrcExtCds.indexOf(sourceExtension.srcExtCd)>-1){
                    sourceExtensionOptions.push({
                        value: sourceExtension.srcExtCd,
                        // text: sourceExtension.brCode + " " + sourceExtension.srcExtDesc
                        text: sourceExtension.srcExtDesc
                    });
                }
            }
        }else{
            for (let sourceExtension of this.jsonDataService.data.srcExt) {
                if (sourceExtension?.issCd === this.userDetails?.issCd) {
                    sourceExtensionOptions.push({
                        value: sourceExtension.srcExtCd,
                        // text: sourceExtension.brCode + " " + sourceExtension.srcExtDesc
                        text: sourceExtension.srcExtDesc
                    });
                }
            }
        }
        sourceExtensionOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return sourceExtensionOptions;
    }
    setSourceExtensionViaInput(): void {
        for (const sourceExtension of this.getSourceExtensions()) {
            if (sourceExtension.text === this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].value) {
                this.policyHeaderDetailsForm.controls["sourceExtensionDetail"].setValue(sourceExtension);
            }
        }
    }

    //* BMA AUTO-COMPLETE FUNCTIONS */
    getBMAs(): any[] {
        let bmaOptions = [];
        for (let bma of this.jsonDataService.data.bma) {
            bmaOptions.push({
                value: bma.bmaCd,
                text: bma.bmaDesc
            });
        }
        bmaOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return bmaOptions;
    }
    setBmaViaInput(): void {
        for (const bma of this.getBMAs()) {
            if (bma.text === this.coverageInfo.controls["bmaDetail"].value) {
                this.coverageInfo.controls["bmaDetail"].setValue(bma);
            }
        }
    }

    //* REFERROR CODE AUTO-COMPLETE FUNCTIONS */
    getReferrorCodes(): any[] {
        let referrorCodeOptions: any[] = [];
        const referrorList: any[] = this.jsonDataService.data.referror;
        for (let referrorCode of referrorList) {
            if (this.jsonDataService.data.srcExt.filter((srcExt: any) => srcExt.issCd === this.userDetails.issCd)?.map((a: any) => a.srcExtCd).includes(referrorCode?.srcExtCd)) {
                referrorCodeOptions.push({
                    value: referrorCode.referrorCd,
                    text: referrorCode.referrorDesc
                });
            }
        }
        referrorCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return referrorCodeOptions;
    }
    setReferrorCodeViaInput(): void {
        for (const referrorCode of this.getReferrorCodes()) {
            if (referrorCode.text === this.policyHeaderDetailsForm.controls["referrorCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["referrorCodeDetail"].setValue(referrorCode);
            }
        }
    }

    //* PROJECT CODE AUTO-COMPLETE FUNCTIONS */
    getProjectCodes(): any[] {
        let projectCodeOptions = [];
        for (let projectCode of this.jsonDataService.data.project) {
            projectCodeOptions.push({
                value: projectCode.projectCd,
                text: (projectCode.bmCode ? projectCode.bmCode + " - " : "") + projectCode.projectDesc
            });
        }
        projectCodeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return projectCodeOptions;
    }
    setProjectCodeViaInput(): void {
        for (const projectCode of this.getProjectCodes()) {
            if (projectCode.text === this.policyHeaderDetailsForm.controls["projectCodeDetail"].value) {
                this.policyHeaderDetailsForm.controls["projectCodeDetail"].setValue(projectCode);
            }
        }
    }

}
